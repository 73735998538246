import React, { forwardRef, useState, useEffect } from 'react';
import cn from 'classnames';
import aa from 'search-insights';
import { useRouter } from 'next/router';
import { Image as ShopifyImage } from '@ts/shopify-storefront-api';
import { LENS_COLORS, BASE_FRAME_NAMES, DEFAULT_DEMOS, DEFAULT_CATEGORIES, PRODUCT_TYPES } from '@constants';
import { ALGOLIA_SEARCH_INDEXES, EMPTY_PRODUCT, LOCALE_DICT, generateVariantUrl, getShopifyIdFromGID, isFalsy, generateLifestyleImages } from '@utils/index';
import { trackSelectedProduct } from '@services/analytics/trackers';
import { useCustomer } from '@services/shopify';
import { useLozengeData, useBaseFrame } from '@utils/hooks';
import { CardProps } from '@ts/components';
import { useTailoredExperience } from '@services/contentful';
import { useCartContext } from '@context';
import { CarouselModal } from '@components';
import { getProductDataTag } from '@utils/dataTags';
import RichMedia from '../RichMedia';
import CardContent from '../CardContent';
import ButtonGroup from '../ButtonGroup';
import Tags from '../Tags';
import styles from './Card.module.scss';
import Copy from './Copy';
import VariantController from './VariantController';

// TODO
// - add "useProduct" hook, compare rendering
// - add "primary action"
// - test in "Search" story

const Card = forwardRef<HTMLDivElement, Omit<CardProps, 'children'>>(({
	aspectRatio = '4/3',
	alreadyInCart = false,
	buttonGroupType = 'side-by-side',
	compact = false,
	description,
	hoverable = true,
	noBorder = false,
	noImage = false,
	quantity = 1,
	containerType = 'vertical',
	contentType = 'vertical',
	isError = false,
	isFetching = false,
	isLoading = false,
	openMinicartOnPurchase = true,
	parentCollectionHandle,
	product: inheritedProduct = EMPTY_PRODUCT,
	primaryAction = 'cart',
	primaryActionData,
	secondaryAction = 'none',
	showAction = false,
	secondaryActionData,
	showDescription = false,
	customTags,
	showCollectionLozenge = false,
	showTags = false,
	showVariantControls = false,
	showLensController = false,
	supplemental,
	variant: inheritedVariant,
	state: inheritedState = null,
	dispatch: inheritedDispatch = null,
	productRemoved = false,
	label = 'View',
	className,
	onMouseEnter,
	isMobile,
	ImgClickHandler,
	bundleKey,
	searchPosition,
	dataTags = {
		'button': {},
		'zoom': {},
		'favorite': {},
	},
	pageType,
	showCarouselModal = false,
	...rest
}: Omit<CardProps, 'children'>, ref) => {
	let lifestyleImages;
	const [isBaseLifeStyleAvailable, setIsBaseLifeStyleAvailable] = useState(false);

	const classes = cn(styles.container, className, {
		[styles.containerVertical]: containerType === 'vertical',
		[styles.containerHorizontal]: containerType === 'horizontal',
		[styles.containerLineItem]: containerType === 'line-item',
		[styles.containerBuildFlow]: containerType === 'build-flow',
		[styles.containerHoverable]: hoverable,
		[styles.containerNoBorder]: noBorder,
		[styles.containerProductRemoved]: productRemoved,
	});
	const { data: customer } = useCustomer();
	const { searchQueryId } = useCartContext();
	const { locale } = useRouter();
	const countryCode = LOCALE_DICT[locale].countryCode;
	const [ownedState, ownedDispatch] = useBaseFrame(inheritedProduct, inheritedVariant, showLensController);
	const state = inheritedState ?? ownedState;
	const { product = inheritedProduct, variant = inheritedVariant } = state ?? {};

	if (inheritedVariant && variant.option === inheritedVariant.option && inheritedVariant?.metafields?.shipmentInfo) {
		variant.metafields = inheritedVariant.metafields;
	}
	const lozengeData = useLozengeData(product);
	const dispatch = inheritedDispatch ?? ownedDispatch;

	//--- UTM Param ---//
	const router = useRouter();
	const { data: tailoredExperience } = useTailoredExperience();
	const utmParam = tailoredExperience ? `&utm_term=${tailoredExperience?.utmTerm}` : '';

	const isSunglassesPDP = router.pathname === '/sunglasses/[demo]/[handle]';
	const typePDP = router.query?.demo as string;
	const topLifestyleImages = product?.normalizedMetafields?.lifestyleImage;
	const isTopLifeStyleImgAvailable = router.route.includes('top-frames') && topLifestyleImages;
	const isBaseFrame = product?.type === PRODUCT_TYPES.BASE_FRAME || product?.type === PRODUCT_TYPES.BASE_FRAME_SR;

	const linkToProduct = generateVariantUrl({
		variant: inheritedVariant ?? variant,
		option: Boolean(variant.option) ? variant.option : (inheritedVariant?.name ?? variant?.name),
		collection: parentCollectionHandle ?? lozengeData?.lozengeHandle ?? typePDP, // lozenge data should only be used in Algolia contexts (or where a collection isn't in scope) -- Recs, Search, etc.
		sunLensColor: isSunglassesPDP ? LENS_COLORS.BLACK : state?.lens,
		isBlueLight: router.route.includes('blue-light'),
	});

	const collectionPath = linkToProduct?.split(product.handle)[0];
	const hasCategory = DEFAULT_CATEGORIES.some((category) => router.asPath.includes(category))

	if (typePDP && hasCategory && isBaseFrame) {
		lifestyleImages = generateLifestyleImages({ demo: router.query?.demo as typeof DEFAULT_DEMOS[number], category: router.route.includes('sunglasses') ? 'sunglasses' : 'eyeglasses', name: product.name as `The ${typeof BASE_FRAME_NAMES[number]}` });
	}

	useEffect(() => {
		const checkImageUrls = async (url: string) => {
			const image = new Image();
			image.onload = () => setIsBaseLifeStyleAvailable(true);
			image.onerror = () => setIsBaseLifeStyleAvailable(false);
			image.src = url;
		};

		if (lifestyleImages) {
			checkImageUrls(lifestyleImages.carousel.url);
		}
	}, [lifestyleImages, typePDP, hasCategory, isBaseFrame]);

	// Could this live in trackers.ts ?
	const productClickEvent = () => {
		// Algolia
		aa('sendEvents', [
			{
				eventType: 'click',
				eventName: 'Product Clicked',
				index: ALGOLIA_SEARCH_INDEXES[countryCode]?.SHOPIFY_PRODUCTS,
				userToken: `${customer ? getShopifyIdFromGID(customer.id) : `guest-user-${Math.floor(Math.random() * 1000000)}`}`,
				objectIDs: [`${getShopifyIdFromGID(variant.product.id)}`, `${getShopifyIdFromGID(variant.id)}`],
				...(searchPosition && searchQueryId && { queryID: searchQueryId, positions: [searchPosition, searchPosition] }),
			},
		]);

		// Elevar
		trackSelectedProduct({
			variant,
			path: collectionPath,
		});
	};

	if (isError) return null;
	if (isFetching || isLoading) return <div>Loading...</div>;

	const image = state?.image ?? variant.image;

	// Focused on parity for now, but Possible to just have one? Useful to apply this to other product types?
	const containerDataTag = getProductDataTag(product.type, product.name);

	const isEmptyVariant = isFalsy(variant.handle);
	const Card = (
		<div data-testid='product-card' ref={ref} {...containerDataTag} className={classes} onMouseEnter={onMouseEnter} {...rest}>
			{!noImage && (
				<RichMedia
					containerType={containerType}
					href={!ImgClickHandler && !isEmptyVariant && `${linkToProduct}${utmParam}`}
					src={image?.url}
					title={product.name}
					aspectRatio={aspectRatio}
					variant={variant}
					ImgClickHandler={showCarouselModal ? undefined : (ImgClickHandler || productClickEvent)}
					pageType={pageType}
					isModalPresent={showCarouselModal}
					hoverSrc={
						isTopLifeStyleImgAvailable
							? (topLifestyleImages as ShopifyImage).url
							: isBaseLifeStyleAvailable
								? lifestyleImages?.carousel?.url
								: ''
					}
				/>
			)}
			<CardContent containerType={containerType} type={contentType} compact={compact} hoverable={hoverable} noBorder={noBorder} variant={variant} >
				{showTags && (
					<Tags
						containerType={containerType}
						compact={compact}
						product={product}
						lozengeData={lozengeData}
						customTags={customTags}
						showCollectionLozenge={showCollectionLozenge}
					/>
				)}
				<Copy
					quantity={quantity}
					description={description}
					compact={compact}
					product={product}
					showAction={showAction}
					showDescription={showDescription}
					state={state}
					supplementalCopy={supplemental}
					variant={variant}
					containerType={containerType}
				/>
				{showVariantControls && (
					<VariantController dispatch={ownedDispatch} product={product} showLensController={showLensController} state={state} />
				)}
				<ButtonGroup
					primaryActionData={primaryActionData}
					secondaryActionData={secondaryActionData}
					buttonGroupType={buttonGroupType}
					compact={compact}
					dispatch={dispatch}
					primaryAction={primaryAction}
					product={product}
					secondaryAction={secondaryAction}
					state={state}
					variant={variant}
					productRemoved={productRemoved}
					linkToProduct={linkToProduct}
					isMobile={isMobile}
					parentCollectionHandle={parentCollectionHandle}
					productClickEvent={productClickEvent}
					alreadyInCart={alreadyInCart}
					openMinicartOnPurchase={openMinicartOnPurchase}
					bundleKey={bundleKey}
					label={label}
					dataTags={dataTags}
				/>
			</CardContent>
		</div>
	);

	return (showCarouselModal ? <CarouselModal variant={variant}>
		{Card}
	</CarouselModal> : Card
	);
});

Card.displayName = 'Card';

export default Card;
