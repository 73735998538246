import React from 'react';
import { Button, Flex } from '@components';
import { Title } from '@components/typography';
import styles from './EmptyCart.module.scss';

const EmptyCart = () => {
	return (
		<Flex column className={styles.container} maxWidth>
			<Title style={{ margin: '1.6rem auto 2.4rem' }}>Your cart is currently empty</Title>

			<Flex column gap={4}>
				<Button color='white' label='Explore Eyewear' href={'/eyeglasses/all'} />
				<Button color='white' label='Explore Sunglasses' href={'/sunglasses/all'} />
				<Button color='white' label='Explore Top Frames' href={'/top-frames'} />
			</Flex>
		</Flex>
	);
};

export default EmptyCart;
