import {
	generateMetafieldQueries,
	productMetafieldIdentifiers,
	variantMetafieldIdentifiers,
} from '../generate-metafield-queries';

const productMetafieldsFragment = /* GraphQL */ `
	fragment productMetafields on Product {
		${generateMetafieldQueries(productMetafieldIdentifiers)}
	}
`;

const productFieldsFragment = /* GraphQL */ `
	fragment productFields on Product {
		availableForSale
		description @include(if: $includeDescription)
		descriptionHtml @include(if: $includeDescription)
		handle
		id
		productType
		tags
		title
		totalInventory
		options { # DNE in BF Collections, make togglable
			id
			name
			values
		}
		priceRange {
			minVariantPrice {
				amount
				currencyCode
			}
			maxVariantPrice {
				amount
				currencyCode
			}
		}
		collections(first: 20) @skip(if: $skipCollections) {
			edges {
				node {
					handle
					metafield(key: "lozenge_key", namespace: "custom_fields") {
						id
						key
						value
					}
				}
			}
		}
		images(first: 200) @skip(if: $skipImages) {
			edges {
				node {
					id
					url
					height
					width
					altText
				}
			}
		}
		variants(first: $maxVariants) @skip(if: $skipVariants) {
			pageInfo {
				hasNextPage
				hasPreviousPage
			}
			edges {
				node {
					id
					title
					sku
					compareAtPrice {
						amount
						currencyCode
					}
					requiresShipping
					availableForSale
					price {
						amount
						currencyCode
					}
					image {
						id
						url
						height
						width
						altText
					}
					${generateMetafieldQueries(variantMetafieldIdentifiers)}
				}
			}
		}
		variantBySelectedOptions(selectedOptions: $selectedOptions) @include(if: $includeSpecificFrameVariant) {
			id
			title
			sku
			compareAtPrice {
				amount
				currencyCode
			}
			requiresShipping
			availableForSale
			price {
				amount
				currencyCode
			}
			image {
				url
				height
				width
			}
			${generateMetafieldQueries(variantMetafieldIdentifiers)}
		}
	}
`;

export { productMetafieldsFragment, productFieldsFragment };
