import { SwatchController } from "@components";
import { FRAME_COLORS, LENS_COLORS, PRODUCT_TYPES } from "@constants";
import { BaseFrameState } from "@utils/hooks/useBaseFrame";
import { normalizeProductType } from "@utils/normalizers";

const VariantController = ({ product, state, dispatch, showLensController }) => {
	if (!(product.variants.length > 1)) return null;

	const type = normalizeProductType(product.type);

	switch (type) {
		case PRODUCT_TYPES.BASE_FRAME || PRODUCT_TYPES.BASE_FRAME_SR:
			return (
				<>
					<SwatchController
						options={Object.values(FRAME_COLORS)}
						callback={dispatch}
						name={product.name}
						selected={(state as BaseFrameState).frame}
						type={'frame'}
						alignment='left'
						activateOnHover
						parent={'product-card'}
					/>
					{showLensController && (
						<SwatchController
							options={Object.values(Object.values(LENS_COLORS))}
							callback={dispatch}
							name={product.name}
							selected={(state as BaseFrameState).lens ?? LENS_COLORS.BLACK}
							title={'with'}
							type={'lens'}
							alignment='left'
							activateOnHover
							parent={'product-card'}
						/>
					)}
				</>
			);

		default:
			return null;
	}
};

export default VariantController;
