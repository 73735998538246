import { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { useRouter } from 'next/router';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { BASE_FRAME_NAMES, ALSO_WORTH_CHECKOUT_COPY, MEDIUM_LARGE_WIDTH, LOCALE_DICT } from '@constants';
import { 
	BaseFrameBundle,
	Button,
	CartHeader,
	Checklist,
	Container,
	EmptyCart,
	Flex,
	Footer,
	FreeShippingTracker,
	HorizontalCard,
	LineItemCard,
	Loading,
	SidebarLayout,
	Spacer,
	Subtotal,
	TopFrameRecommendation,
} from '@components';
import { Caption } from '@components/typography';
import { useDebounce, useHasMounted, useIsMobile, useLocalStorage } from '@utils/hooks';
import { useProductQuery, useCart, useCustomer } from '@services/shopify';
import { trackCartView } from '@services/analytics/trackers';
import { useSiteSettings } from '@services/contentful';
import { useCartContext } from '@context';
import { getThreshold, getChecklist, getTopContainerClass, hasDuplicatedKeys, getCartBreakdown } from '../cart-utils';
import styles from './Minicart.module.scss';

const Minicart = ({ forceMobileStyle = false, footer = null, isMinicart = false }) => {
	const [checkoutText, setCheckoutText] = useState('Checkout');
	const [activeFrame] = useLocalStorage<typeof BASE_FRAME_NAMES[number]>('activeFrame', 'Larkin');
	const { locale, push } = useRouter();
	const countryCode = LOCALE_DICT[locale].countryCode;
	const { data: cart, isLoading: isCartLoading } = useCart();
	const { data: siteSettings, isLoading: isSiteSettingsloading } = useSiteSettings();
	const { isCartMutating, isUploadingRx } = useCartContext();
	const { data: customer } = useCustomer();
	const { data: pairCare, isLoading: isPairCareLoading } = useProductQuery('pair-care', {}, { queryRefreshVars: [locale], locale });
	const { data: blueLightLens, isLoading: isBlueLightLensLoading } = useProductQuery('blue-light-filtering');
	const { data: premiumLens, isLoading: isPremiumLensLoading } = useProductQuery('premium-ultra-thin');
	const { data: wallHanger } = useProductQuery('wall-hanger', { country: countryCode }, { queryRefreshVars: [locale] });
	const { data: topFrameCase } = useProductQuery('top-frame-case', { country: countryCode }, { queryRefreshVars: [locale] });
	const { data: cleaningKit } = useProductQuery('cleaning-kit', { country: countryCode }, { queryRefreshVars: [locale] });
	const anyAsyncLoading = isSiteSettingsloading || isCartLoading || isPairCareLoading || isBlueLightLensLoading || isPremiumLensLoading;
	const isMobile = useIsMobile({ maxWidth: MEDIUM_LARGE_WIDTH });
	const isMounted = useHasMounted();
	const debouncedTrackCartView = useDebounce(trackCartView);
	const threshold = getThreshold(locale, siteSettings);
	const isTrackingFreeShipping = !!threshold && !isSiteSettingsloading;
	const isKeepShoppingTestActive = useFeatureIsOn('is-cart-cta-test');
	const accessories = [topFrameCase, wallHanger, cleaningKit].filter(accessory => !!accessory && !cart?.lines.some(line => line.variant.product.name === accessory.name));
	const { topFrames, bundles, others } = getCartBreakdown(cart);
	const hasBundleOrBaseTops = bundles?.some(bundle => !!bundle?.tops || !!bundle?.base);
	const isCheckoutDisabled = isCartMutating || isUploadingRx;
	const cta = (
		<>
			<Subtotal subtotal={cart?.subtotal} />
			<Button
				onClick={async () => {
					setCheckoutText('Loading');
					if (hasDuplicatedKeys(cart)) {
						console.error(`Cart with id ${cart.id} has duplicated keys`, cart);
					}

					if (!customer) {
						push(cart?.checkoutUrl.replace('pair-eyewear.myshopify.com', 'shop.paireyewear.com'));
						return;
					}

					try {
						const res = await fetch('/api/multipass', {
							method: 'POST',
							body: JSON.stringify({
								...customer,
								return_to: cart?.checkoutUrl.replace('pair-eyewear.myshopify.com', 'shop.paireyewear.com'),
							}),
						});
						const { url } = await res.json();
						push(url);
					} catch (error) {
						console.error(error);
					}
				}}
				data-checkout
				fullWidth
				label={checkoutText}
				disabled={!cart?.lines.length || isCheckoutDisabled}
				style={{ pointerEvents: isCheckoutDisabled ? 'none' : 'auto', cursor: isCheckoutDisabled ? 'not-allowed' : 'pointer', height: '4.8rem' }}
			/>
			{ isKeepShoppingTestActive && !isMinicart &&
				<>
					<Spacer size={'0.8rem'} />
					<Button href='/' label='Keep Shopping' color='white' fullWidth/>
				</>
			}
		</>
	);

	useEffect(() => {
		if (cart) debouncedTrackCartView(cart);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (checkoutText === 'Loading') {
			setTimeout(() => {
				setCheckoutText('Checkout');
			}, 1500);
		}
	}, [checkoutText]);

	if (anyAsyncLoading || !isMounted) return <Loading />;

	return (
		<>
			{forceMobileStyle && <SidebarLayout.Header inDrawer={forceMobileStyle} />}
			<SidebarLayout.Root forceMobileStyle={forceMobileStyle} cartStyle isMinicart={isMinicart}>
				<SidebarLayout.Content forceMobileStyle={forceMobileStyle} isMinicart={isMinicart} cartStyle>
					<Flex className={forceMobileStyle ? styles.containerInDrawer : styles.container} column center fullWidth gap={4} >
						<CartHeader itemCount={cart?.totalQuantity ?? 0} subtotal={cart?.subtotal} />
						{isTrackingFreeShipping && <FreeShippingTracker locale={locale} freeShippingThreshold={getThreshold(locale, siteSettings)} cartTotal={cart?.subtotal} />}
						{!cart?.lines.length && <EmptyCart />}
						{bundles.map(bundle => bundle?.base ?
							<BaseFrameBundle
								isLoading={anyAsyncLoading}
								pairCare={pairCare}
								key={bundle.key}
								bundle={bundle}
								cartId={cart?.id}
								forceMobileStyle={forceMobileStyle}
							/>
							: null
						)}
						{topFrames.length > 0 && (
							<Flex column gap={4} maxWidth className={getTopContainerClass(forceMobileStyle, styles)}>
								<Caption className={styles.bundleTitle}>My Top Frames</Caption>
								{topFrames.map(({ optimistic, top }) => (
									<LineItemCard
										key={`line-${top?.id ?? nanoid()}`}
										data={top}
										dataSource={'shopify'}
										optimistic={optimistic}
										dataTags={{
											'button': {},
											'zoom': {
												'data-tops-zoom': 'cart',
											},
										}}
									/>
								))}
							</Flex>
						)}
						{others.length > 0 && (
							<Flex column gap={4} maxWidth className={getTopContainerClass(forceMobileStyle, styles)} data-testid='other-items'>
								<Caption className={styles.bundleTitle}>Other Items</Caption>
								{others.map(({ item, optimistic }) => (
									<LineItemCard key={`line-${item?.id ?? nanoid()}`} data={item} dataSource={'shopify'} optimistic={optimistic} />
								))}
							</Flex>
						)}
						{hasBundleOrBaseTops && !isMobile && !isMinicart && (
							<TopFrameRecommendation activeFrame={activeFrame} bundles={bundles} isMinicart={forceMobileStyle} />
						)}
					</Flex>
					{!!footer && !isMobile && (
						<Flex fullWidth className={styles['footer']}>
							<Footer footer={footer} page='cart' />
						</Flex>
					)}
				</SidebarLayout.Content>
				{hasBundleOrBaseTops && (isMobile || isMinicart) && <TopFrameRecommendation activeFrame={activeFrame} bundles={bundles} isMinicart={forceMobileStyle} />}
				<SidebarLayout.Sidebar isMinicart={isMinicart} forceMobileStyle={forceMobileStyle} cartStyle>
					{accessories.length > 0 && (
						<Container backgroundColor='white' pad={4} borderRadius={4} className={styles['upsellContainer']} data-testid='accessories-upsell'>
							<Caption className={cart?.lines.length || forceMobileStyle ? styles['upsellTitle'] : styles['upsellTitle--empty']}>
								Complete your order with our accessories
							</Caption>
							<Flex column gap={3} align='center'>
								{accessories.map(u => {
									if (!u || cart?.lines.some(line => line.variant.product.name === u.name)) return null;

									return (
										<HorizontalCard
											key={u?.handle}
											product={u}
											variant={u?.variants[0]}
											alreadyInCart={cart?.lines.some(line => line.variant.product.name === u.name)}
											openMinicartOnPurchase={false}
											showTags={false}
											description={ALSO_WORTH_CHECKOUT_COPY[u?.handle]}
											dataTags={{
												'button': u?.handle ? { 'data-accessory-added': u?.handle } : {},
												'zoom': {},
												'favorite': {},
											}}
										/>
									);
								})}
							</Flex>
						</Container>
					)}
					{!!cart?.lines.length && (
						<Container className={styles['checklistPadded']} backgroundColor='white'>
							<Checklist title='With your purchase: ' backgroundColor='transparent' list={getChecklist(cart, locale, siteSettings)} />
						</Container>
					)}
					{!forceMobileStyle && !isMobile && <SidebarLayout.CTA>{cta}</SidebarLayout.CTA>}
				</SidebarLayout.Sidebar>
			</SidebarLayout.Root>
			{!!footer && isMobile && (
				<Flex fullWidth className={styles['footer']}>
					<Footer footer={footer} page='cart' />
				</Flex>
			)}
			{(forceMobileStyle || isMobile) && <SidebarLayout.CTA forceMobileStyle>{cta}</SidebarLayout.CTA>}
		</>
	);
};

export default Minicart;
