import { DivergentArrows, Icon } from '@components';
import { IconProps } from '../Icon/Icon';

type ZoomProps = Partial<IconProps> & { iconOnly?: boolean };

const Zoom = ({
	height,
	width,
	iconOnly = undefined,
	label = 'Click to Zoom',
	shape = 'square',
	...rest
}: ZoomProps) => {

	return iconOnly ? (
		<>
			<DivergentArrows width={width} height={height} {...rest} />
		</>
	) : (
		<>
			<Icon shape={shape} label={label} hoverable {...rest}>
				<DivergentArrows width={width} height={height} />
			</Icon>
		</>
	)
};

export default Zoom;
