import { InstantSearch } from 'react-instantsearch';
import { useRouter } from 'next/router';
import { Drawer, SearchIcon } from '@components';
import { SearchResults } from '@components/search';
import { SearchClient } from '@services/algolia';
import { ALGOLIA_SEARCH_INDEXES } from '@utils/algolia';
import { LOCALE_DICT } from '@constants';
import styles from './SearchDrawer.module.scss';

const maxHits = 16;

const SearchDrawer = ({ openMinicartRef }) => {
	const { locale } = useRouter();
	const countryCode = LOCALE_DICT[locale].countryCode ?? 'US';
	const localeAlgoliaIndex = ALGOLIA_SEARCH_INDEXES[countryCode]?.SHOPIFY_PRODUCTS;

	return (
		<Drawer >
			<Drawer.Trigger asChild>
				<div data-nav-icon='search' className={styles['navIcon']} tabIndex={0}>
					<SearchIcon label='Search' />
				</div>
			</Drawer.Trigger>
			<InstantSearch searchClient={SearchClient} indexName={localeAlgoliaIndex} future={{ preserveSharedStateOnUnmount: false }}>
				<Drawer.Content fullHeightOnMobile blurOverlay={false}>
					<div className={styles.container} data-search-drawer>
						<SearchResults index={localeAlgoliaIndex} hitsPerPage={maxHits} openMinicartRef={openMinicartRef} />
					</div>
				</Drawer.Content>
			</InstantSearch>
		</Drawer>
	);
};

SearchDrawer.displayName = 'SearchDrawer';

export default SearchDrawer;
