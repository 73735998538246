import { Button, Flex } from '@components';
import { Title } from '@components/typography';
import styles from '../NoResults/NoResults.module.scss';

const PopularSearches = ({ handleSearch }) => {
	// ! This array should be sourced from Algolia as soon as clean data is available
	const popular = ['Sun Tops', 'Harry Potter', 'Sparkle', 'Base Frame'];

	return (
		<div id='popular' className={styles.popular}>
			<Flex column gap={3}>
				<Title>Popular Searches</Title>
				{popular.map(p => (
					<Button
						key={`search-suggestion-${p}`}
						style={{
							padding: '0 0.8rem',
							textAlign: 'left',
							height: '4rem',
						}}
						fullWidth
						linkStyle
						onClick={() => handleSearch(p)}
					>
						{p}
					</Button>
				))}
			</Flex>
		</div>
	);
};

export default PopularSearches;
