import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import * as ToastPrimitive from '@radix-ui/react-toast';
import cn from 'classnames';
import { AnimatePresence, m } from 'framer-motion';
import { Attention, Button, Checkmark, Close, Flex, InformationIcon } from '@components';
import { Paragraph, Title } from '@components/typography';
import { ComponentProps } from '@ts/components';
import { slideFromPaddedBottom } from '@utils/motions';
import { useClickOutside } from '@utils/hooks';
import variables from '@styles/export.module.scss';
import { useToastContext } from '@utils/context';
import styles from './Toast.module.scss';

export type ToastComponentProps = ComponentProps<HTMLLIElement> & {
	type?: 'info' | 'success' | 'error' | 'neutral';
	title: string;
	message: string;
	showTimer?: boolean;
	duration?: number;
	/** If true, user will need to click a CTA before the Toast closes */
	actionRequired?: boolean;
	ctaLabel?: string;
	ctaColor?: 'green' | 'blue' | 'white' | 'transparent-light' | 'transparent-dark';
};

const ONE_HOUR = 3600000;

const Toast = forwardRef<HTMLLIElement, ToastComponentProps>(
	(
		{
			type = 'neutral',
			title,
			message,
			showTimer,
			className,
			duration = 5000,
			actionRequired = false,
			ctaLabel = 'Got it',
			ctaColor = 'white',
		},
		ref
	) => {
		// We should really be using Infinity instead of ONE_HOUR, but there's a guard preventing this
		// See the Primitive source code https://github.com/radix-ui/primitives/blob/1b05a8e35cf35f3020484979086d70aefbaf4095/packages/react/toast/src/Toast.tsx#L504
		// By definition, a Toast can't require an action (bc then it would be an Alert Dialog!) but this is fine for our current needs
		const toastDuration = actionRequired ? ONE_HOUR : duration;

		const [timeLeft, setTimeLeft] = useState(toastDuration);

		const fallbackRef = useRef<HTMLLIElement>(null);

		useClickOutside(ref ?? fallbackRef, () => actionRequired && setToast(null));

		// Handle timer bar animations
		// Note that we've chosen not to abstract this out into a reusable hook because we need this to run conditionally
		// But there's a great explanation of this approach here https://css-tricks.com/using-requestanimationframe-with-react-hooks/
		const requestRef = useRef<number>();
		const timerStartRef = useRef<number>();
		const step: FrameRequestCallback = useCallback(
			timestamp => {
				if (timerStartRef.current !== undefined) {
					const elapsedTime = timestamp - timerStartRef.current;
					const time = toastDuration - elapsedTime;
					setTimeLeft(time < 0 ? 0 : time);
				}
				requestRef.current = window.requestAnimationFrame(step);
			},
			[toastDuration]
		);
		const { toast, setToast, toastProps } = useToastContext();

		useEffect(() => {
			if (toast && showTimer) {
				timerStartRef.current = performance.now();
				requestRef.current = window.requestAnimationFrame(step);
			}
			return () => window.cancelAnimationFrame(requestRef.current);
		}, [toast, showTimer, step]);

		// Use appropriate styles for toaster type -- mostly just changing background color
		const classes = cn(styles.container, className, {
			[styles['container--info']]: type === 'info',
			[styles['container--error']]: type === 'error',
			[styles['container--success']]: type === 'success',
		});

		// Map appropriate icon to toaster type -- default state has no icon
		const toastIcons = {
			info: <InformationIcon color={variables.gray0} className={styles.info} />,
			error: (
				<Attention
					height={20}
					width={20}
					fill='white'
					label='Attention'
					color={variables.gray0}
					className={styles.attention}
				/>
			),
			success: <Checkmark color={variables.gray0} className={styles.check} />,
		};

		return (
			<AnimatePresence>
				{toast && (
					<ToastPrimitive.Root
						duration={toastDuration}
						ref={ref ?? fallbackRef}
						asChild
						onOpenChange={toast => setToast(toast)}
						forceMount
						{ ...toastProps }
					>
						<m.li className={classes} {...slideFromPaddedBottom} transition={{ duration: 0.3 }} data-toast-message>
							{toastIcons[type]}
							<Flex column justify='end' gap={4} fullWidth>
								<Flex column align='start' justify='end' gap={1} fullWidth>
									<Flex fullWidth>
										<ToastPrimitive.Title asChild>
											<Title style={{ flexGrow: 1 }}>{title}</Title>
										</ToastPrimitive.Title>
										<ToastPrimitive.Close asChild>
											<Close
												height={12}
												width={12}
												label='Close'
												color={variables.gray0}
												wrapperClass={styles.close}
												onClick={() => setToast(null)}
											/>
										</ToastPrimitive.Close>
									</Flex>
									<ToastPrimitive.Description asChild>
										<Paragraph>{message}</Paragraph>
									</ToastPrimitive.Description>
								</Flex>
								{actionRequired && (
									<Flex justify='end' gap={3} fullWidth>
										<Button size='small' color={ctaColor} label={ctaLabel} onClick={() => setToast(null)} />
									</Flex>
								)}
							</Flex>
							{showTimer && (
								<div className={styles.timer} style={{ width: `${(100 * timeLeft) / toastDuration}%` }} />
							)}
						</m.li>
					</ToastPrimitive.Root>
				)}
			</AnimatePresence>
		);
	}
);

Toast.displayName = 'Toast';

export default Toast;
