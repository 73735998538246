import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useToastContext } from '@context';
import { TOAST } from '@constants';
import { CartMutationArgs, CartMutationLineItem, NormalizedCart } from '@ts/cart';
import { generateCartBundles, normalizeCart } from '@utils/normalizers/normalize-cart';
import { Mutation } from '@ts/shopify-storefront-api';
import { cartLinesAddMutation, cartLinesRemoveMutation, fetchStorefrontApi, throwCartErrors, useCartId } from '@services/shopify';

type useCartReplaceMutation = {
	lineItemIds: string[];
	lineItems: CartMutationArgs['lineItems'];
};

const useCartReplace = (): UseMutationResult => {
	const queryClient = useQueryClient();
	const { showToast } = useToastContext();
	const { data: cartId } = useCartId();

	return useMutation(
		['cart', 'replace', { cartId }],
		async ({ lineItemIds, lineItems }: useCartReplaceMutation) => {
			const { cartLinesRemove }: Mutation = await fetchStorefrontApi(cartLinesRemoveMutation, {
				variables: { cartId, lineIds: lineItemIds },
			});
			const { userErrors: removeErrors } = cartLinesRemove;

			const addPayload: CartMutationLineItem[] = lineItems.map(
				({ quantity = 1, variant, customAttributes }) => ({
					quantity,
					merchandiseId: variant.id,
					attributes: customAttributes,
				})
			);
			const { cartLinesAdd }: Mutation = await fetchStorefrontApi(cartLinesAddMutation, {
				variables: { cartId, lines: addPayload },
			});
			const { userErrors: addErrors, cart } = cartLinesAdd;

			throwCartErrors([...removeErrors, ...addErrors]);
			const normalizedCart = normalizeCart(cart);
			queryClient.setQueryData(['cart', { cartId }], normalizedCart);
			return normalizedCart;
		},
		{
			onMutate: true ? null : ({ lineItemIds }) => {
				queryClient.cancelQueries(['cart']);
				const cartSnapshot = queryClient.getQueryData(['cart', { cartId }]);

				queryClient.setQueryData<NormalizedCart>(['cart', { cartId }], previousCart => {
					const linesAfterRemoval = previousCart.lines.filter(line => !lineItemIds.includes(line.id));
					const updatedBundles = generateCartBundles(linesAfterRemoval, true);
					return {
						...previousCart,
						bundles: updatedBundles,
						lines: linesAfterRemoval,
						subtotal: linesAfterRemoval.reduce((res, cur) => res + (cur.variant.price.amount * cur.quantity), 0),
					};
				});

				return { cartSnapshot };
			},
			onError: (error, props, context) => {
				console.error(`Error replacing item in cart ${cartId}: ${error}`);
				queryClient.setQueryData(['cart', { cartId }], context.cartSnapshot);
				queryClient.invalidateQueries(['cart']);
				showToast(TOAST.LAST_ACTION_ERROR);
			},
			onSettled: () => {
				queryClient.invalidateQueries(['cart']);
			},
		}
	);
};

export { useCartReplace };
