import { useRouter } from 'next/router';
import { CartIcon, Flex } from '@components';
import { TypographyButton, Title } from '@components/typography';
import { formatCurrency } from '@utils/shopify';
import { LOCALE_CODES, LOCALE_DICT } from '@constants';

type CartHeaderProps = {
	subtotal: number;
	itemCount: number;
};

const CartHeader = ({ subtotal, itemCount }: CartHeaderProps) => {
	const { locale } = useRouter();
	const currencyCode = LOCALE_DICT[locale].currencyCode;
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
	const total = formatCurrency({
		amount: subtotal ?? 0,
		currencyCode,
		minDigits: 2,
		maxDigits: 2,
		locale,
	}, showCurr);

	return (
		<Flex style={{ padding: '2.4rem 1.6rem 0.8rem' }} maxWidth justify='between' align='center'>
			<Flex gap={3} align='center'>
				<CartIcon label='Cart' />
				<Flex gap={2} align='baseline'>
					<Title data-test-subtotal={subtotal}>My Cart: </Title>
					<TypographyButton>
						{total}
					</TypographyButton>
				</Flex>
			</Flex>
			<TypographyButton small>{`(${itemCount} item${itemCount === 1 ? '' : 's'})`}</TypographyButton>
		</Flex>
	);
};

export default CartHeader;
