/* eslint-disable max-lines */
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
	BASE_FRAME_LENS_OPTIONS,
	BASE_FRAME_LENS_VARIANTS,
	BASE_FRAME_RX_TYPE_VARIANTS,
	BaseVariantPriceDictionary,
	RX_TYPE,
} from '@utils/constants/base-skus';
import { FRAME_COLORS, LENSES_PACKAGES } from '@constants';
import { alphabetize } from '@utils/arrays';
import { MoneyV2, SelectedOption } from '@ts/shopify-storefront-api';
import { parseBaseFrameVariant } from '@utils/shopify';
import { normalizeMetafields } from '@utils/normalizers';
import { NormalizedVariant } from '@ts/product';
import fetchStorefrontApi from '../fetch-storefront-api';
import { cleanGraphqlResponse } from '..';
import baseFrameVariantQuery from '../queries/base-frame-variant-query';
import baseFrameVariantsQuery from '../queries/all-base-frame-variants-query';

export type VariantState = {
	handle: string;
	'Color': FRAME_COLORS;
	'Rx Type': BASE_FRAME_RX_TYPE_VARIANTS;
	'Lens': BASE_FRAME_LENS_VARIANTS;
	'country'?: string;
	isLensExpansionTest?: boolean;
};

type VariantFetchResponse = {
	id: string;
	price: MoneyV2;
	selectedOptions: SelectedOption;
	title: `${FRAME_COLORS} / ${RX_TYPE} / ${BASE_FRAME_LENS_VARIANTS}`;
	metafields: NormalizedVariant['metafields'];
}

export type DynamicBaseFrameVariant = {
	state: VariantState;
	data: {
		variantBySelectedOptions: VariantFetchResponse;
	};
	isLoading: boolean;
};

const defaultOptions: VariantState = {
	'handle': 'the-casper-black',
	'Color': FRAME_COLORS.BLACK,
	'Rx Type': 'Single Vision',
	'Lens': 'None',
	'country': 'US',
	'isLensExpansionTest': false,
};

export function createBaseFrameVariant(selectedLenses: Array<BASE_FRAME_LENS_OPTIONS>, shouldReverse = false) {
	return selectedLenses.length > 0 ? (alphabetize(selectedLenses, shouldReverse).join(' + ') as BASE_FRAME_LENS_VARIANTS) : 'None';
}

const useBaseFrameVariant = (state = defaultOptions): DynamicBaseFrameVariant => {
	const { isLensExpansionTest, ...rest } = state;
	const { data, isLoading, refetch } = useQuery(
		['variant-fetch', rest, rest.country],
		async () => {
			return await fetchBaseFrameVariant({
				...rest,
				'Rx Type': rest['Rx Type'] || defaultOptions['Rx Type'],
			});
		},
		{
			keepPreviousData: true,
		}
	);

	useEffect(() => {
		refetch();
	}, [isLensExpansionTest]);

	return { state: rest, data, isLoading };
};

export const fetchBaseFrameVariant = async (options = defaultOptions) => {
	try {
		const { handle, country = 'US', isLensExpansionTest, ...argOptions } = options;

		const fetchVariant = async (variantOptions) => {
			const { product } = await fetchStorefrontApi(baseFrameVariantQuery, {
				variables: {
					handle,
					selectedOptions: Object.entries(variantOptions).map(([name, value]) => ({ name, value })),
					country,
				},
			});
			const cleaned = cleanGraphqlResponse<{ variantBySelectedOptions: VariantFetchResponse }>(product);
			const { variantBySelectedOptions: { id, selectedOptions, title, price, ...rest } } = cleaned;
			return {
				variantBySelectedOptions: {
					id,
					price,
					selectedOptions,
					title,
					metafields: normalizeMetafields(rest),
				},
			};
		};

		let cleaned = await fetchVariant(argOptions);

		if (argOptions.Lens === 'None' && cleaned.variantBySelectedOptions === null) {
			cleaned = await fetchVariant({ ...argOptions, Lens: 'No Add-ons' });
		} else if (argOptions.Lens.includes('Blue Light + Light Responsive + Premium Plus')) {
			cleaned = await fetchVariant({ ...argOptions, Lens: 'Light Responsive + Premium Plus' });
		} else if (argOptions.Lens.includes('Blue Light + Light Responsive')) {
			cleaned = await fetchVariant({ ...argOptions, Lens: 'Light Responsive' });
		}

		return cleaned;
	} catch (error) {
		console.error(`Error fetching Base Frame Variant: ${error}`);
	}
};

export const useBaseFrameVariantPrices = (handle: string, country = 'US', isLensExpansionTest = false, initialData?: BaseVariantPriceDictionary) => {
	const variantPricesQuery = useQuery(
		['base-frame-variant-prices', handle, country],
		async () => {
			return await fetchAllBaseFrameVariants(handle, country, isLensExpansionTest);
		},
		{
			initialData,
			//TODO: query doesn't seem to update when the frame color changes
		}
	);

	useEffect(() => {
		variantPricesQuery.refetch();
	}, [isLensExpansionTest]);

	return variantPricesQuery;
};

export const fetchAllBaseFrameVariants = async (handle: string, country: string, isLensExpansionTest = false) => {
	try {
		const { product } = await fetchStorefrontApi(baseFrameVariantsQuery, {
			variables: {
				handle,
				country,
			},
		});
		const { variants } = cleanGraphqlResponse<{ variants: any }>(product);

		return variants.reduce((a, c) => {
			const price = {
				...c.price,
				amount: isLensExpansionTest && !!c?.['meta_cr39AbTest']?.value ? c['meta_cr39AbTest'].value : c.price.amount,
			};

			if (c.title.includes('None')) {
				const altTitle = c.title.replace('None', 'No Add-ons');
				a[altTitle] = price;
			}
			if (c.title.includes('No Add-ons')) {
				const altTitle = c.title.replace('No Add-ons', 'None');
				a[altTitle] = price;
			}
			a[c.title] = price;
			return a;
		}, {}) as BaseVariantPriceDictionary;
	} catch (error) {
		console.error(`Error fetching all Base Frame Variants with handle ${handle}: ${error}`);
	}
};

export const getVariantPriceDiff = ({
	lookup,
	current,
	selectedLenses,
	lens,
	rxType,
	lensPackage,
	sunLensException,
	locale,
	currencyCode,
}: {
	lookup: BaseVariantPriceDictionary;
	current: DynamicBaseFrameVariant;
	selectedLenses?: Array<BASE_FRAME_LENS_OPTIONS>;
	lens?: BASE_FRAME_LENS_OPTIONS;
	rxType?: RX_TYPE;
	lensPackage?: LENSES_PACKAGES;
	sunLensException?: boolean;
	locale: string;
	currencyCode: string;
}) => {
	const info = parseBaseFrameVariant(current.data.variantBySelectedOptions.title);

	if (lens) {
		const isBasicPlan = lensPackage === LENSES_PACKAGES.BASIC;
		const hasPlan = !!lensPackage;
		let currentVariantWithLens: keyof BaseVariantPriceDictionary = `${current.state.Color} / ${current.state['Rx Type']
		} / ${createBaseFrameVariant([...new Set([...info.lensType, lens])], isBasicPlan)}`;
		let currentVariantWithoutLens: keyof BaseVariantPriceDictionary = `${current.state.Color} / ${current.state['Rx Type']
		} / ${createBaseFrameVariant(info.lensType.filter(v => v !== lens), isBasicPlan)}`;

		
		if (isBasicPlan && currentVariantWithLens.split(' / ')[2] === 'Blue Light') {
			currentVariantWithLens = `${current.state.Color} / ${current.state['Rx Type']} / Blue Light + Basic`;
		}

		if (currentVariantWithLens.split(' / ')[2] === 'Premium Plus + Basic') {
			currentVariantWithLens = `${current.state.Color} / ${current.state['Rx Type']} / Premium Plus`;
		}

		if (currentVariantWithLens.split(' / ')[2] === 'Premium Plus + Blue Light') {
			currentVariantWithLens = `${current.state.Color} / ${current.state['Rx Type']} / Blue Light + Premium Plus`;
		}
		
		if (currentVariantWithLens.split(' / ')[2] === 'Premium Plus + Blue Light + Basic') {
			currentVariantWithLens = `${current.state.Color} / ${current.state['Rx Type']} / Blue Light + Premium Plus`;
		}

		if (isBasicPlan && currentVariantWithoutLens.split(' / ')[2] === 'None') {
			currentVariantWithoutLens = `${current.state.Color} / ${current.state['Rx Type']} / Basic`;
		}
		
		if (isBasicPlan && currentVariantWithoutLens.split(' / ')[2] === 'Blue Light') {
			currentVariantWithoutLens = `${current.state.Color} / ${current.state['Rx Type']} / Blue Light + Basic`;
		}


		
		if (current.state['Rx Type'] === RX_TYPE.NON_RX && !isBasicPlan) {
			currentVariantWithLens = `${current.state.Color} / ${current.state['Rx Type']} / ${createBaseFrameVariant([lens], isBasicPlan)}`;
		}

		try {
			return {
				with: {
					amount:
						parseInt(lookup[currentVariantWithLens].amount) -
						parseInt(hasPlan && !isBasicPlan ? current.data.variantBySelectedOptions.metafields.cr39AbTest : current.data.variantBySelectedOptions.price.amount),
					currencyCode: lookup[currentVariantWithLens].currencyCode,
					locale,
				},
				without: {
					amount:
						parseInt(hasPlan && !isBasicPlan ? current.data.variantBySelectedOptions.metafields.cr39AbTest : current.data.variantBySelectedOptions.price.amount) -
						parseInt(lookup[currentVariantWithoutLens].amount),
					currencyCode: lookup[currentVariantWithoutLens].currencyCode,
					locale,
				},
			};
		} catch (error) {
			console.error(`Error getting pricing information for one or both of these variants: ${currentVariantWithLens}, ${currentVariantWithoutLens}
							${error}
				`);
			return {
				with: {
					amount: 0,
					currencyCode,
					locale,
				},
				without: {
					amount: 0,
					currencyCode,
					locale,
				},
			};
		}
	}

	if (lensPackage) {
		const currentVariantWithRx: keyof BaseVariantPriceDictionary = `${current.state.Color} / ${rxType ?? 'Single Vision'} / None`;
		const currentVariantWithoutRx: keyof BaseVariantPriceDictionary = `${current.state.Color} / ${rxType ?? 'Single Vision'} / Basic`;
		const premium = lookup[`${current.state.Color} / ${rxType ?? 'Single Vision'} / Premium Plus`]?.amount - lookup[currentVariantWithoutRx]?.amount;
		const cr39Cost = lookup[currentVariantWithRx]?.amount - lookup[currentVariantWithoutRx]?.amount;
		const amount = {
			[LENSES_PACKAGES.BASIC]: 0,
			[LENSES_PACKAGES.STANDARD]: cr39Cost,
			[LENSES_PACKAGES.PREMIUM]: premium,
		};

		// In this case I assumed it is safe to go for the same amount for with or without
		return {
			with: {
				amount: amount[lensPackage],
				currencyCode: lookup[currentVariantWithRx]?.currencyCode,
				locale,
			},
			without: {
				amount: amount[lensPackage],
				currencyCode: lookup[currentVariantWithoutRx]?.currencyCode,
				locale,
			},
		};
	}

	if (rxType && info.lensColor && sunLensException) {
		const currentVariantWithRx: keyof BaseVariantPriceDictionary = `${current.state.Color
		} / ${rxType} / ${createBaseFrameVariant(info.lensType)}`;

		// TODO: what does "without" mean in this context? Single Vision is safe to assume for now...
		const currentVariantWithoutRx: keyof BaseVariantPriceDictionary = `${current.state.Color
		} / Non-RX / ${createBaseFrameVariant(info.lensType)}`;

		return {
			with: {
				amount: parseInt(lookup[currentVariantWithRx]?.amount) - parseInt(lookup[currentVariantWithoutRx]?.amount),
				currencyCode: lookup[currentVariantWithRx]?.currencyCode,
				locale,
			},
			without: {
				amount: parseInt(lookup[currentVariantWithoutRx]?.amount) - parseInt(lookup[currentVariantWithRx]?.amount),
				currencyCode: lookup[currentVariantWithoutRx]?.currencyCode,
				locale,
			},
		};
	}

	if (rxType) {
		const currentVariantWithRx: keyof BaseVariantPriceDictionary = `${current.state.Color} / ${rxType} / None`;
		// TODO: what does "without" mean in this context? Single Vision is safe to assume for now...
		const currentVariantWithoutRx: keyof BaseVariantPriceDictionary = `${current.state.Color} / Single Vision / None`;

		return {
			with: {
				amount: parseInt(lookup[currentVariantWithRx]?.amount) - parseInt(lookup[currentVariantWithoutRx]?.amount),
				currencyCode: lookup[currentVariantWithRx]?.currencyCode,
				locale,
			},
			without: {
				amount: parseInt(lookup[currentVariantWithRx]?.amount) - parseInt(lookup[currentVariantWithoutRx]?.amount),
				currencyCode: lookup[currentVariantWithoutRx]?.currencyCode,
				locale,
			},
		};
	}
};

export default useBaseFrameVariant;
