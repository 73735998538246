import React from 'react';
import { slideUp } from '@utils/motions';
import { Swipe } from '@components';
import { Paragraph } from '@components/typography';
import { NormalizedVariant } from '@ts/product';
import Flex from '../Flex';
import Img from '../Img';
import Modal from '../Modal';
import styles from './CarouselModal.module.scss';

type CarouselModalProps = {
	variant: NormalizedVariant;
	children?: React.ReactNode;
};

const CarouselModal = ({ variant, children }: CarouselModalProps) => {
	const { image } = variant;
	//TODO: change the design of the modal to match the new design
	return (
		<Modal>
			{children}
			<Modal.Content className={styles.modal} customAnimation={slideUp}>
				<div className={styles.imageContainer}>
					<Img
						className={styles.image}
						src={image.url}
						alt={image.altText}
						width={image.width ?? 800}
						height={image.height ?? 350}
					/>
					<Flex
						align='center'
						justify='center'
						className={`${styles.bottomMessage}`}
					>
						<Swipe />
						<Paragraph>Swipe to view more</Paragraph>
					</Flex>
				</div>
			</Modal.Content>
		</Modal>
	);
};

export default CarouselModal;
