import { ReactNode } from 'react';
import { CartIcon, ConditionalLink } from '@components';
import { calculateCartQuantity } from '@utils/cart';
import { NormalizedCartLine } from '@ts/cart';
import { useCart } from '@services/shopify';
import styles from './CartNavButton.module.scss';

type CartNavButtonProps = JSX.IntrinsicElements['button'] & {
	children?: ReactNode,
	lines?: Array<NormalizedCartLine>,
	isLoading?: boolean,
	isError?: boolean,
	title?: string,
	href?: string,
	handleMinicartClick?: () => void,
};

const CartNavButton = (
	{
		children = null,
		href = null,
		title = 'Go to Your Cart',
		handleMinicartClick = null,
		...rest
	}: CartNavButtonProps) => {

	const { data, isError, isLoading } = useCart();
	const lines = data?.lines ?? [];
	const cartQuantity = lines?.length ? calculateCartQuantity(lines) : 0;
	const showCartCount = !isLoading && !isError && lines?.length;

	return (
		<ConditionalLink href={href} condition={!!href}>
			<button
				type='button'
				className={styles['container']}
				tabIndex={0}
				title={title}
				{...(handleMinicartClick && { onClick: () => handleMinicartClick() })}
				{...rest}
			>
				<CartIcon label='cart' className={styles['icon']} />
				{showCartCount ? <div className={styles['count']}><span>{cartQuantity}</span></div> : null}
				{children}
			</button>
		</ConditionalLink>
	)
};


export default CartNavButton;
