import { ReactNode, useState } from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import cn from 'classnames';
import { TooltipIcon } from '@components';
import { Caption } from '@components/typography';
import styles from './Tooltip.module.scss';

type TooltipProps = {
	message: string | ReactNode;
	children?: ReactNode;
	contentClassName?: string;
	iconClassName?: string;
	dataTags?: Record<string, unknown>;
};

const Tooltip = ({ message, children, contentClassName = '', iconClassName = '', dataTags }: TooltipProps) => {
	const [open, setOpen] = useState(false);
	const contentClasses = cn(styles['message'], contentClassName);
	const buttonClasses = cn(styles['button'], iconClassName);

	return (
		<TooltipPrimitive.Provider delayDuration={100}>
			<TooltipPrimitive.Root open={open} onOpenChange={setOpen}>
				<TooltipPrimitive.Trigger asChild>
					<button
						className={buttonClasses}
						onClick={e => {
							e.preventDefault();
							e.stopPropagation();
							setOpen(true);
						}}
						{...dataTags}
					>
						{children ?? <TooltipIcon label='tooltip icon' />}
					</button>
				</TooltipPrimitive.Trigger>
				<TooltipPrimitive.Portal>
					<TooltipPrimitive.Content
						className={styles['content']}
						side='bottom'
						sideOffset={8}
						collisionPadding={16}
						onClick={e => {
							e.stopPropagation();
						}}
					>
						<div className={contentClasses}>
							<Caption>{message}</Caption>
						</div>
					</TooltipPrimitive.Content>
				</TooltipPrimitive.Portal>
			</TooltipPrimitive.Root>
		</TooltipPrimitive.Provider>
	);
};

export default Tooltip;
