/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper/modules';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import {
	SUBMISSION_METHODS,
	READER_STRENGTHS,
	CUSTOMER_JOURNEYS,
	DELETE_BASE_FRAME,
	LENSES_SUPPORT_COPY,
	LOCALE_CODES, LENSES_PACKAGES,
} from '@constants';
import { Button, CheckboxUpsell, Flex, Loading, Select, Img, LineItemCard, Modal, PairCare, PrescriptionOptions } from '@components';
import { TypographyButton, Heading, Paragraph, Title, Caption } from '@components/typography';
import { useCart } from '@services/shopify';
import { Bundle, DoctorInfoAttributes } from '@ts/cart';
import { SubmissionMethod } from '@ts/poms';
import { NormalizedProduct } from '@ts/product';
import { CurrencyCode } from '@ts/shopify-storefront-api';
import { useProductQuery } from '@services/shopify';
import {
	FRAME_COLORS,
	LENS_COLORS,
	LOCALE_DICT,
	generateSunglassImages,
	getBaseName,
	getCheckoutTokenFromGID,
	useParseBaseFrameVariant,
	formatCurrency,
	findLensPackageInLensOptions,
} from '@utils/index';
import variables from '@styles/export.module.scss';
import { CART_LINE_ATTRIBUTE_KEYS } from '@utils/constants/cart';
import { useCartContext } from '@context';
import useBaseFrameVariant, {
	createBaseFrameVariant,
	getVariantPriceDiff,
	useBaseFrameVariantPrices,
} from '@services/shopify/hooks/useBaseFrameVariant';
import { BASE_FRAME_LENS_OPTIONS } from '@utils/constants/base-skus';
import 'swiper/css';
import 'swiper/css/effect-fade';
import { fetchContentful } from '@services/contentful/client';
import { normalizeContentfulEntry } from '@utils/normalizers/normalize-contentful';
import { useIsLensExpansionTest } from '@utils/hooks';
import { CopyGroupFields } from '@ts/contentful';
import { addcustomAttributes, getCollectionPathFromProperties, getEditPath, getReaderStrengthPayload, getSubmissionMethodPayload } from '../cart-utils';
import { emptyUpsellLine, emptyUpsellProduct } from '../cart-constants';
import styles from './BaseFrameBundle.module.scss';

const { REMIND } = SUBMISSION_METHODS;

type BaseFrameBundleProps = {
	cartId: string;
	bundle: Bundle;
	isLoading: boolean;
	pairCare: NormalizedProduct;
	forceMobileStyle?: boolean;
};

const BaseFrameBundle = ({ bundle, pairCare, forceMobileStyle = false, isLoading }: BaseFrameBundleProps) => {
	const isBothUpsellsVariant = useFeatureIsOn('both-upsells-test');
	const isPremiumVariant = useFeatureIsOn('show-premium-upsell');
	const isCartUpsellTest = isBothUpsellsVariant || isPremiumVariant;

	const { data: cart } = useCart();
	const { handleCartRemove, handleCartUpdate } = useCartContext();
	const router = useRouter();
	const { locale } = router;
	const countryCode = LOCALE_DICT[locale].countryCode;
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;

	const { base, optimistic: optimisticState, key: bundleKey } = bundle;
	const { frame, prescription, insurance } = base;
	const { variant, properties, id: frameId } = frame;
	const { handle = '', option: fullVariantOption, name: productFrameTitle, type: varianttype, image: variantImg, name: frameTitle } = variant;
	const { url: variantImgUrl } = variantImg;
	const { _customerJourney, _readerStrength, _submissionMethod = REMIND, _customerType } = properties;
	const { id: insuranceId } = insurance ?? { id: '' };
	const { type: prescriptionType } = prescription;
	const isSunglasses = _customerJourney === CUSTOMER_JOURNEYS.SUNGLASSES;
	const { isLensExpansion: isLensExp } = useIsLensExpansionTest();
	const isLensExpansion = isLensExp && !isSunglasses;

	const isKidsFrame = _customerType === 'kids';
	const { frameColor, lensColor, lensType, rxType } = useParseBaseFrameVariant(fullVariantOption);
	const lensTypesToDisplay = lensType.filter(lens => {
		if (isLensExpansion) {
			return lens !== BASE_FRAME_LENS_OPTIONS.CR39 && lens !== BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS;
		}
		return lens !== BASE_FRAME_LENS_OPTIONS.CR39;
	});

	const isBlueLensInCart = lensType.includes(BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT);
	const isPremiumLensInCart = lensType.includes(BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS);
	const isLightResponsiveInCart = lensType.includes(BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE);
	const isNonRx = rxType === 'Non-RX'
	const isReaders = rxType === 'Readers';
	const isBlueLightUpsellVisible = !isBlueLensInCart && !isSunglasses && (!isPremiumVariant || !isCartUpsellTest);
	const isPremiumUpsellVisible = !isSunglasses && !isNonRx && !isReaders && !isKidsFrame && (!isCartUpsellTest) && !isLensExpansion && !isPremiumLensInCart;
	const isRxOptionsVisible = !!bundleKey && !isLoading && !isNonRx && !isReaders;
	const showUpsellIncentiveCopy = !isSunglasses && ((isBlueLightUpsellVisible && !isLightResponsiveInCart));
	const sunGlasses = [];
	const prescriptionLensPrice = { amount: 0, currencyCode: CurrencyCode.Usd };
	const submissionMethodState = useState(_submissionMethod as SubmissionMethod);
	const { data: product } = useProductQuery(handle, { country: countryCode });
	const variantImages = product?.variantImages;
	const lensPack = isLensExpansion ? findLensPackageInLensOptions(lensType) : null;

	const BaseFrameVariant = useBaseFrameVariant({
		handle: base.frame.variant.handle,
		Color: frameColor,
		'Rx Type': rxType,
		Lens: createBaseFrameVariant(lensType, lensPack === LENSES_PACKAGES.BASIC),
		country: countryCode,
		isLensExpansionTest: isLensExpansion,
	});

	const { data: variantPrices, isLoading: variantPricesAreLoading } = useBaseFrameVariantPrices(base.frame.variant.handle, countryCode, isLensExpansion);

	const { data: lensUpsellCopyGroup } = useQuery(['lens-upsell-copy'], async (): Promise<CopyGroupFields> => {
		const copyGroup = await fetchContentful<CopyGroupFields>({
			'content_type': 'copyGroup',
			'fields.slug': 'build-flow-lens-options-copy',
			'limit': 1,
		});
		return normalizeContentfulEntry<CopyGroupFields>(copyGroup) as Promise<CopyGroupFields>;
	});

	const sunglassImages = useMemo(() =>
		generateSunglassImages({
			name: getBaseName(productFrameTitle),
			baseColor: frameColor as FRAME_COLORS,
			lensColor: (isSunglasses ? lensColor : '') as LENS_COLORS,
			length: 3,
		}), [frameColor, sunGlasses[0]?.variant?.option, lensColor]);

	const removeAllFromBundle = () => {
		handleCartRemove([
			...(insuranceId && [insuranceId]),
			frameId,
		], variant, getCollectionPathFromProperties(properties));
	};

	const updateReaderStrength = (strength: typeof READER_STRENGTHS[number]) => {
		const payload = getReaderStrengthPayload(strength, frame, properties);
		handleCartUpdate([payload]);
	};

	const updatePDMeasurement = async (pd: number) => {
		const payload = addcustomAttributes(
			{
				key: CART_LINE_ATTRIBUTE_KEYS.PD_MEASUREMENT,
				value: pd.toString(),
			},
			frame,
			properties
		);
		await handleCartUpdate([payload]);
	}

	const updateBundleSubmissionMethod = (extraProperties: DoctorInfoAttributes = {}) => {
		const isEditableBaseFrame = !properties || !frameId;
		const isSubmissionChanging = submissionMethodState[0] === _submissionMethod;

		if (isEditableBaseFrame || isSubmissionChanging) return;

		const payload = getSubmissionMethodPayload(properties, submissionMethodState[0], frame, extraProperties);
		frame && handleCartUpdate([payload]);
	};

	const memoizedRxOptions = useMemo(() => {
		return <PrescriptionOptions
			updatePDMeasurement={updatePDMeasurement}
			updateBundleSubmissionMethod={updateBundleSubmissionMethod}
			submissionMethodState={submissionMethodState}
			bundleKey={bundleKey}
			checkoutToken={getCheckoutTokenFromGID(cart?.id)}
			hasMeasuredPd={!!properties[CART_LINE_ATTRIBUTE_KEYS.PD_MEASUREMENT]}
		/>
	}, [base, submissionMethodState, isLoading]);

	const baseFrameImages = (image, index) => (
		<SwiperSlide key={index} className={'swiper-no-swiping'}>
			<Flex center>
				<Img needsPlaceholder alt={image.altText ?? ''} src={image.url} height={155} width={311} />
			</Flex>
		</SwiperSlide>
	);

	const handleEdit = async () => {
		const { basePath, queryParams } = getEditPath(bundleKey, frameColor, properties, _customerJourney, handle);
		const currentPath = router.asPath?.split('?')[0];

		if (currentPath === basePath) document.getElementById('minicart')?.click();

		router.push(basePath + queryParams);
	};

	if (!base) return null;
	if (isLoading || BaseFrameVariant.isLoading) return <Loading />;

	const lenses = (
		<>
			{/* LENSES */}
			{lensTypesToDisplay.map(lensLine => {
				return <CheckboxUpsell
					disabled={isSunglasses}
					key={`lensLine-${lensLine}`}
					base={base}
					bundleKey={bundleKey}
					tooltip
					lensUpsell={lensLine}
					variantPrices={variantPrices}
					currentVariant={BaseFrameVariant}
					lensUpsellCopy={lensUpsellCopyGroup}
					isRedesign={isCartUpsellTest && !isSunglasses}
					forceMobileStyle={forceMobileStyle}
					lensPack={lensPack}
				/>
			})}
			{/* LENS UPSELLS */}
			{isBlueLightUpsellVisible && <CheckboxUpsell base={base} lensUpsell={BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT} lensUpsellCopy={lensUpsellCopyGroup} bundleKey={bundleKey} variantPrices={variantPrices} currentVariant={BaseFrameVariant} isRedesign={isCartUpsellTest} forceMobileStyle={forceMobileStyle} disabled={isLightResponsiveInCart} lensPack={lensPack} />}
			{isPremiumUpsellVisible && <CheckboxUpsell base={base} lensUpsell={BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS} lensUpsellCopy={lensUpsellCopyGroup} bundleKey={bundleKey} tooltip variantPrices={variantPrices} currentVariant={BaseFrameVariant} isRedesign={isCartUpsellTest} forceMobileStyle={forceMobileStyle} lensPack={lensPack} />}
		</>
	);

	const lensPackPriceDiff = isLensExpansion && !variantPricesAreLoading ? getVariantPriceDiff({
		lookup: variantPrices,
		current: BaseFrameVariant,
		lensPackage: lensPack,
		rxType,
		locale,
		currencyCode: LOCALE_DICT[locale].currencyCode,
	}) : null;

	const rxPriceDiff = isLensExpansion && !variantPricesAreLoading ? getVariantPriceDiff({
		lookup: variantPrices,
		current: BaseFrameVariant,
		rxType,
		locale,
		currencyCode: LOCALE_DICT[locale].currencyCode,
	}) : null;
	const totalPackPrice = isLensExpansion && !!rxPriceDiff && !!lensPackPriceDiff ? {
		with: {
			...lensPackPriceDiff.with,
			amount: lensPackPriceDiff.with.amount + rxPriceDiff.with.amount,
		},
	} : null;

	return (
		<Flex column gap={4} maxWidth className={forceMobileStyle ? styles.containerInDrawer : styles.container}>
			<Flex justify='end' gap={4}>
				<Button size='small' linkStyle onClick={handleEdit}>
					Edit
				</Button>
				<TypographyButton small style={{ color: variables.gray4 }}>
					|
				</TypographyButton>
				<Modal>
					<Modal.Trigger asChild>
						<Button size='small' linkStyle data-open-remove-modal={productFrameTitle}>Remove</Button>
					</Modal.Trigger>
					<Modal.Content center removePadding className={styles.deleteBaseFrameModal}>
						<Flex className={styles.imageContainer} align='center' justify='center'>
							<img src={isSunglasses ? sunglassImages[0].url : variantImgUrl} alt='frame variant' />
						</Flex>
						<div className={styles.bodyContainer}>
							<Heading tag='h5'>{DELETE_BASE_FRAME.TITLE}</Heading>
							<Paragraph>
								{DELETE_BASE_FRAME.LABEL} <b>{productFrameTitle}</b>.{' '}{DELETE_BASE_FRAME.PROMPT}
							</Paragraph>
							<Flex gap={3}>
								<Modal.Close asChild>
									<Button color='white'>Cancel</Button>
								</Modal.Close>
								<Modal.Close asChild>
									<Button
										onClick={() => removeAllFromBundle()}
										style={{ flex: 2 }}
										data-remove={productFrameTitle}
										data-type-remove={varianttype}
									>
										Remove
									</Button>
								</Modal.Close>
							</Flex>
						</div>
					</Modal.Content>
				</Modal>
			</Flex>
			{!!variantImages && !isLoading && (
				<Flex center>
					<Swiper
						loop
						direction='horizontal'
						speed={600}
						modules={[Autoplay, EffectFade]}
						effect='fade'
						autoplay={{ delay: 2500 }}
						noSwiping
						style={{ maxWidth: forceMobileStyle ? '33rem' : '' }}
						className={styles['swiper-container']}
					>
						{isSunglasses
							? sunglassImages.map((sunglassImage, index) => baseFrameImages(sunglassImage, index))
							: variantImages[frameColor].map((color, index) => baseFrameImages(color, index))
						}
					</Swiper>
				</Flex>
			)}
			<Paragraph className={styles.bundleTitle}>My Base Frame</Paragraph>
			{base && !isLoading && (
				<>
					<LineItemCard
						key={frameTitle}
						data={frame}
						dataSource={'shopify'}
						deletionCallback={removeAllFromBundle}
						optimistic={optimisticState}
						baseFrame={base}
						textOnly
					/>
					<Flex column gap={2} fullWidth className={isCartUpsellTest || isLensExpansion ? styles.section : null}>
						{(isCartUpsellTest || isLensExpansion) && <Caption>Protect Your Lenses</Caption>}
						<PairCare bundle={bundle} pairCare={pairCare} />
					</Flex>
				</>
			)}
			{_readerStrength && (
				<Flex align='center' justify='between' className={styles.readerStrengthDropdown}>
					<Title>Reader Strength</Title>
					<Select
						values={READER_STRENGTHS.map(rs => `+${rs}`)}
						handler={updateReaderStrength}
						placeholder={`+${_readerStrength}` ?? 'Choose Strength'}
					/>
				</Flex>
			)}
			<Flex column gap={4} fullWidth className={styles.section}>
				{!isCartUpsellTest && (
					<Flex justify='between' align='end'>
						<Flex column>
							<Paragraph className={styles.bundleTitle} style={{ paddingLeft: 0, marginTop: 0 }}>My Lenses</Paragraph>
							{isLensExpansion && <Paragraph className={styles.lensPackage}>{rxType} in {lensPack}</Paragraph>}
						</Flex>
						{isLensExpansion && totalPackPrice ? (
							<TypographyButton small style={{ color: variables.gray4 }}>
								{formatCurrency(totalPackPrice?.with, showCurr)}
							</TypographyButton>
						) : <div />}
					</Flex>
				)}
				{isCartUpsellTest && (
					<Flex justify='between' align='end'>
						<Flex column>
							<Caption>My Lenses</Caption>
							{isLensExpansion && <Paragraph className={styles.lensPackage}>{rxType} in {lensPack}</Paragraph>}
						</Flex>
						{isLensExpansion && totalPackPrice ? (
							<TypographyButton small style={{ color: variables.gray4 }}>
								{formatCurrency(totalPackPrice?.with, showCurr)}
							</TypographyButton>
						) : <div />}
					</Flex>
				)}
				{/* RX TYPE */}
				{!isLensExpansion && (
					<CheckboxUpsell
						className={isCartUpsellTest ? styles.transparent : null}
						disabled
						base={base}
						product={{
							...emptyUpsellProduct,
							name: rxType ?? prescriptionType,
							handle: rxType,
							price: prescriptionLensPrice,
						}}
						bundleKey={bundleKey}
						line={{
							...emptyUpsellLine,
							title: rxType,
						}}
						preSelectedTooltip
						rxType={rxType}
						variantPrices={variantPrices}
						currentVariant={BaseFrameVariant}
					/>
				)}
				{showUpsellIncentiveCopy && isCartUpsellTest && (
					<Flex justify='start' align='start' fullWidth gap={3} className={styles.lensUpsellIncentive}>
						<Caption small>
							{LENSES_SUPPORT_COPY.blueLight}
							{/* {isPremiumVariant && LENSES_SUPPORT_COPY.premiumPlus} */}
							{/* {isBothUpsellsVariant && LENSES_SUPPORT_COPY.both} */}
						</Caption>
					</Flex>
				)}
				{!variantPricesAreLoading && lenses}
			</Flex>
			{isRxOptionsVisible && memoizedRxOptions}
		</Flex>
	);
};

export default BaseFrameBundle;
