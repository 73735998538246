import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { AnnouncementBar, HeaderThin, Logo, UserActionNav } from '@components';
import { NavMenu } from '@components/navbar';
import { useWindowDimensions } from '@utils/hooks';
import styles from './Header.module.scss';

const Header = ({ announcements, header, headerVisible = true, thin = false, redirectTo = null }) => {
	const [isMobileNavOpen, setMobileNavOpen] = useState(false);
	const { width: viewportWidth } = useWindowDimensions();
	const router = useRouter();
	const isHomepage = router.pathname === '/';
	const iso = router.locale;

	useEffect(() => {
		setMobileNavOpen(false);
	}, [viewportWidth]);

	return (
		<section className={styles.container}>
			{announcements && announcements?.isEnabled && (isHomepage || !announcements?.showOnHomepageOnly) && (
				<AnnouncementBar slides={announcements?.slides} iso={iso} />
			)}
			{headerVisible && (
				thin ? <HeaderThin redirectTo={redirectTo} /> : (
					<header className={styles.header} role='banner' data-navigation>
						<div className={styles.grid}>
							<div className={styles.logoContainer}>
								<Logo isMobileNavOpen={isMobileNavOpen} setMobileNavOpen={setMobileNavOpen} />
							</div>
							<div className={styles.leftNavContainer}>
								{header && <NavMenu header={header} isMobileNavOpen={isMobileNavOpen} setMobileNavOpen={setMobileNavOpen} />}
							</div>
							<div className={styles.rightNavContainer}>
								<UserActionNav />
							</div>
						</div>
					</header>
				)
			)}
		</section>
	);
};

Header.displayName = 'Header';

export default Header;
