/* eslint-disable max-lines */
// Framer Motion Animations Live Here
import { hexToRgba } from '@utils/hexToRgba';

/*** Transitions ***/
const entryTransition = {
	transition: { duration: 0.6, ease: [0.19, 0.5, 0.22, 1] },
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const exitTransition = {
	transition: { duration: 0.3, ease: [0.19, 1, 0.22, 1] },
};

const springBehavior = {
	transition: { duration: 0.5, type: 'spring', mass: 1, stiffness: 256, damping: 24 },
};

const bouncySpring = {
	transition: { duration: 0.3, type: 'spring', mass: 1, stiffness: 819, damping: 21.4 },
};

const linear = {
	transition: { ease: 'linear', duration: 0.3 },
};

/*** Animations ***/
const exitToRight = {
	// /initial: { x: '-100%', opacity: 0 },
	animate: { x: 0, opacity: 1, ...springBehavior },
	exit: { x: '100%', opacity: 0, ...springBehavior },
};

const fade = {
	initial: { opacity: 0 },
	animate: { opacity: 1, ...springBehavior },
	exit: { opacity: 0, ...springBehavior },
};

const fadeLinear = {
	initial: { opacity: 0 },
	animate: { opacity: 1, ...linear },
	exit: { opacity: 0, ...linear },
};

const notificationBeat = {
	initial: { y: 0, scale: 0.9, ...bouncySpring },
	animate: { y: 0, scale: 1, ...bouncySpring },
};

const notificationPop = {
	initial: { y: 4, ...bouncySpring },
	animate: { y: 0, ...bouncySpring },
};

const slideDownAndFade = {
	initial: { ...fade.initial, y: -16 },
	animate: { ...fade.animate, y: 0 },
	exit: { ...fade.exit, y: -16 },
};

const slideUp = {
	initial: { y: '100vh' },
	animate: { y: 0, ...springBehavior },
	exit: { y: '100vh', ...springBehavior },
};

const slideFromBottom = {
	initial: { y: '100%', x: 0 },
	animate: { y: 0, x: 0, ...springBehavior },
	exit: { y: '100%', x: 0, ...springBehavior },
};

const slideFromTop = {
	initial: { y: '-20%', x: 0, opacity: 0 },
	animate: { y: 0, x: 0, opacity: 1, ...linear },
	exit: { y: '-20%', x: 0, opacity: 0, ...linear },
};

const slideFromPaddedBottom = {
	initial: { y: 'calc(100% + 3.2rem)', x: 0 },
	animate: { y: 0, x: 0, ...springBehavior },
	exit: { y: 'calc(100% + 3.2rem)', x: 0, ...springBehavior },
};

const slideFromRight = {
	initial: { x: '100%', y: 0 },
	animate: { x: 0, y: 0, ...springBehavior },
	exit: { x: '100%', y: 0, ...springBehavior },
};

const slideFromPaddedRight = {
	initial: { x: '120%', y: 0 },
	animate: { x: 0, y: 0, ...springBehavior },
	exit: { x: '120%', y: 0, ...springBehavior },
};

const accordianHeightCollapse = {
	initial: {
		height: 'auto',
		opacity: 1,
	},
	animate: {
		height: 0,
		opacity: 0,
		transition: {
			height: {
				duration: 0.4,
				type: 'spring',
				stiffness: 256,
				damping: 24,
			},
			opacity: {
				duration: 0.25,
				delay: 0.15,
			},
		},
	},
};

const accordianHeightExpansion = {
	initial: {
		height: 0,
		opacity: 0,
	},
	animate: {
		height: 'auto',
		opacity: 1,
		transition: {
			height: {
				duration: 0.4,
				type: 'spring',
				mass: 1,
				stiffness: 256,
				damping: 24,
			},
			opacity: {
				duration: 0.25,
				delay: 0.15,
			},
		},
	},
	exit: {
		height: 0,
		opacity: 0,
		transition: {
			height: {
				duration: 0.4,
				type: 'spring',
				mass: 1,
				stiffness: 200,
				damping: 25,
				delay: 0.1,
			},
			opacity: {
				duration: 0.15,
			},
		},
	},
};

/*** Variants ***/
const changePlacesVariants = {
	hidden: {
		x: '-100%',
		opacity: 0,
		...entryTransition,
	},
	visible: {
		x: '0',
		opacity: 1,
		...entryTransition,
	},
};

const zoomVariants = {
	hover: {
		scale: 1.025, // changed to test PLP Hero usage -- new variant required
	},
};

const calculateBottomColapse = (withButton: boolean, withSubTitle: boolean, elementHeight: number, titleHeight: number): string => {
	const baseValue = -1 * ((elementHeight/10) - 3.2) - 0.8;

	const offsetWithButton = withButton ? 4.8 : 0;
	const offsetWithSubTitle = withSubTitle ? 3.2 : 0;
	const offsetTitle = titleHeight/10;

	const result = baseValue + offsetWithButton + offsetWithSubTitle + offsetTitle;

	return `${result}rem`;
}

const calculateButtonBottom = (withSubTitle: boolean, elementHeight: number): string => {
	const baseValue = (elementHeight/10) - 9.6 + 0.8;
	const offsetWithSubTitle = withSubTitle ? 3.2 : 0;
	const result = baseValue - offsetWithSubTitle;
	return `${result}rem`;
}

const expandVariants = (bgColor: string, withButton = false, withSubTitle = false, elementHeight = 512, titleHeight = 4) => {
	const bottomColapse = calculateBottomColapse(withButton, withSubTitle, elementHeight, titleHeight);
	
	return {
		collapsed: {
			bottom: bottomColapse, // figma has -467px. we need to add 16px to handle the "lip" from the spring motion, then subtract 1px for alignment (border size?),
			backgroundColor: hexToRgba(bgColor, 0),
			...springBehavior,
		},
		expanded: {
			bottom: '-1.6rem',
			backgroundColor: hexToRgba(bgColor, 0.9),
			...springBehavior,
		},
	};
};

const expandButtonVariants = (withSubTitle = false, elementHeight = 512) => {
	const bottomColapse = calculateButtonBottom(withSubTitle, elementHeight);
	return{	
		collapsed: {
			left: '2.4rem',
			bottom: bottomColapse,
			...springBehavior,
		},
		expanded: {
			left: '2.4rem',
			bottom: '4rem',
			...springBehavior,
		},
	}};

export function getMicrocartAnimation(prefersReducedMotion = false) {
	return prefersReducedMotion
		? {
			initial: { 
				opacity: 0,
				y: '-1rem',
			},
			animate: { 
				opacity: 1,
				y: 0, 
			},
			exit: {
				opacity: 0,
				transition: {
					duration: 0.2,
				},
			},
			transition: {
				opacity: 1,
				transition: {
					duration: 0.1,
				},
			},
		}
		: {
			initial: { y: '-50vh' },
			animate: { y: 0 },
			exit: {
				y: '-50vh',
				transition: {
					type: "spring",
					stiffness: 50,
					damping: 5,
					mass: 0.25,
					duration: 1.25,
				},
			},
			transition: {
				type: "spring",
				stiffness: 40,
				damping: 3.5,
				mass: 0.125,
				duration: 0.5,
			},
		};
}

const fadeVariants = {
	fadeIn: {
		opacity: 1,
		...springBehavior,
	},
	fadeOut: {
		opacity: 0,
		...springBehavior,
	},
};

const rotateVariants = {
	rotateL: { rotate: [0, 180], ...linear },
	rotateR: { rotate: [90, 0], ...linear },
};

export const buildFlowLensContainerVariants = {
	show: {
		opacity: 1,
	},
	hidden: {
		opacity: 0,
	},
}

export const buildFlowLensVariants = (isReadersSelected: boolean, shouldReduceMotion: boolean) => ({
	hidden: () => {
		let scalingY = isReadersSelected ? -184 : -96;
		if (shouldReduceMotion) scalingY = 0;

		return { opacity: 0, y: [0, scalingY], transition: { duration: 0.125 } };
	},
	show: () => {
		let scalingY = isReadersSelected ? -184 : -96;
		if (shouldReduceMotion) scalingY = 0;

		return {
			opacity: 1,
			y: [scalingY, 0],
			transition: { type: 'spring', stiffness: 256, damping: 24, duration: 0.125, mass: 1 },
		};
	},
})


const OptionContainerHeight = 54
const OptionContainerPadding = 0
const OptionContainerBorder = 0;
const OptionContainerGap = 8;
const OptionContainerTotalHeight = 
			OptionContainerHeight 
			+ (OptionContainerPadding * 2) 
			+ (OptionContainerBorder * 2)
			+ OptionContainerGap;

export const buildFlowPrescriptionVariants = (index: number) => ({
	inactive: {
		x: '-125%',
		y: 0,
		height: OptionContainerHeight,
	},
	active: {
		x: 0,
		y: -(OptionContainerTotalHeight * index),
		opacity: 1,
		height: OptionContainerHeight,
	},
	initial: {
		x: 0,
		y: 0,
		opacity: 1,
		height: 'auto',
	},
}) 

export const buildFlowPrescriptionVariantsLensExpansion = (index: number, offsetTop) => ({
	inactive: {
		x: 0,
		y: 0,
		opacity: 0,
		zIndex: -1,
	},
	active: {
		x: 0,
		y: -(offsetTop - 1),
		zIndex: 100,
	},
	initial: {
		x: 0,
		y: 0,
		opacity: 1,
		zIndex: 'auto',
	},
})

export {
	changePlacesVariants,
	accordianHeightCollapse,
	exitToRight,
	exitTransition,
	fade,
	fadeLinear,
	notificationBeat,
	notificationPop,
	linear,
	slideDownAndFade,
	slideFromBottom,
	slideFromTop,
	slideFromPaddedBottom,
	slideFromRight,
	slideFromPaddedRight,
	slideUp,
	springBehavior,
	accordianHeightExpansion,
	zoomVariants,
	expandVariants,
	rotateVariants,
	fadeVariants,
	expandButtonVariants,
};
