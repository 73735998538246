import { Dispatch } from 'react';
import { useRouter } from 'next/router';
import { BaseFrameState, BaseFrameStateDispatch } from '@utils/hooks/useBaseFrame';
import { REFLECTIVE_ADDON_PRICE } from '@constants';
import { BASE_FRAME_COST, LOCALE_CODES, formatCurrency } from '@utils/index';
import { CardProps } from '@ts/components';
import { useTailoredExperience } from '@services/contentful';
import MicroButton from './MicroButton';
import StackedButton from './StackedButton';
import SideBySideButton from './SideBySideButton';

export type ButtonGroupProps = {
	state: BaseFrameState;
	dispatch: Dispatch<BaseFrameStateDispatch>;
	linkToProduct: string;
	productClickEvent: () => void;
	alreadyInCart: boolean;
	openMinicartOnPurchase: boolean;
	bundleKey: string;
	label?: string;
	dataTags?: Record<
		'button' | 'zoom' | 'favorite',
		{
			[key: string]: string | boolean;
		}
	>;
} & Required<
	Pick<
		CardProps,
		| 'primaryAction'
		| 'primaryActionData'
		| 'buttonGroupType'
		| 'compact'
		| 'product'
		| 'variant'
		| 'secondaryAction'
		| 'secondaryActionData'
		| 'productRemoved'
		| 'isMobile'
		| 'parentCollectionHandle'
	>
>;
const ButtonGroup = ({
	alreadyInCart,
	buttonGroupType: type,
	compact,
	product,
	variant,
	primaryAction,
	primaryActionData,
	secondaryAction,
	secondaryActionData,
	state,
	dispatch,
	productRemoved,
	linkToProduct,
	isMobile,
	openMinicartOnPurchase,
	parentCollectionHandle,
	productClickEvent,
	bundleKey,
	label,
	dataTags,
}: ButtonGroupProps) => {
	let amount = variant.price.amount;
	const collectionPath = linkToProduct?.split(product.handle)[0];
	const { locale, pathname } = useRouter();
	const { data: tailoredExperience } = useTailoredExperience();
	const utmParam = tailoredExperience ? `&utm_term=${tailoredExperience?.utmTerm}` : '';
	const isReceipt = pathname.includes('/account/orders');
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
	const discount = variant.compareAtPrice ? variant.compareAtPrice?.amount - variant.price.amount : 0;

	amount = variant.type.includes('BASE_FRAME')
		? BASE_FRAME_COST[locale] - discount
		: variant.price.amount + Number(!!(state as BaseFrameState)?.lens?.includes('Reflective')) * REFLECTIVE_ADDON_PRICE;

	const price = { ...variant.price, amount };
	let compareAtPrice = variant.compareAtPrice;

	if (variant.type.includes('BASE_FRAME') && variant.compareAtPrice) {
		compareAtPrice = { ...variant.compareAtPrice, amount: BASE_FRAME_COST[locale] };
	}

	const formattedPrice = formatCurrency(
		{
			amount,
			currencyCode: variant.price.currencyCode,
			minDigits: isReceipt ? 2 : 0,
			maxDigits: isReceipt ? 2 : 0,
			locale,
		},
		showCurr
	);
	const formattedCompareAtPrice = formatCurrency(
		{
			amount: variant.type.includes('BASE_FRAME') ? BASE_FRAME_COST[locale] : variant.compareAtPrice?.amount,
			currencyCode: variant.price.currencyCode,
			minDigits: isReceipt ? 2 : 0,
			maxDigits: isReceipt ? 2 : 0,
			locale,
		},
		showCurr
	);

	switch (type) {
		case 'micro':
			return (
				<MicroButton
					{...{
						secondaryAction,
						secondaryActionData,
						primaryAction,
						primaryActionData,
						variant,
						formattedPrice,
						formattedCompareAtPrice,
						compareAtPrice,
						productRemoved,
						isMobile,
						dataTags,
					}}
				/>
			);
		case 'stacked':
			return (
				<StackedButton
					{...{
						secondaryAction,
						secondaryActionData,
						primaryAction,
						variant,
						isMobile,
						dataTags,
						product,
						dispatch,
						openMinicartOnPurchase,
						parentCollectionHandle,
						collectionPath,
						alreadyInCart,
						price,
						linkToProduct,
						utmParam,
						label,
					}}
				/>
			);
		case 'side-by-side':
			return (
				<SideBySideButton
					{...{
						secondaryAction,
						compact,
						product,
						collectionPath,
						variant,
						isMobile,
						dataTags,
						primaryAction,
						primaryActionData,
						parentCollectionHandle,
						bundleKey,
						openMinicartOnPurchase,
						linkToProduct,
						alreadyInCart,
						productClickEvent,
						price,
						label,
					}}
				/>
			);
		default:
			return null;
	}
};

export default ButtonGroup;
