import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useToastContext } from '@context';
import { TOAST } from '@constants';
import { CartMutationLineItem, NormalizedCart, NormalizedCartLine } from '@ts/cart';
import { generateCartBundles, normalizeCart } from '@utils/normalizers/normalize-cart';
import { calculateOptimisticSubtotal } from '@components';
import { CartLineInput, Mutation } from '@ts/shopify-storefront-api';
import { cartLinesAddMutation, fetchStorefrontApi, throwCartErrors, useCartId } from '@services/shopify';
import { useIsLensExpansionTest } from "@utils/hooks";

type useCartAddMutation = {
	lineItems: CartMutationLineItem[];
};

const useCartAdd = (): UseMutationResult => {
	const queryClient = useQueryClient();
	const { showToast } = useToastContext();
	const { data: cartId } = useCartId();
	const { isLensExpansion } = useIsLensExpansionTest();

	return useMutation(
		['cart', 'add', { cartId }],
		async ({ lineItems }: useCartAddMutation) => {
			const addPayload: CartLineInput[] = lineItems.map(
				({ quantity = 1, variant, customAttributes }) => ({
					quantity,
					merchandiseId: variant.id,
					attributes: customAttributes,
				})
			);
			const { cartLinesAdd }: Mutation = await fetchStorefrontApi(cartLinesAddMutation, {
				variables: { cartId, lines: addPayload },
			});
			const { userErrors, cart } = cartLinesAdd;
			throwCartErrors(userErrors);
			const normalizedCart = normalizeCart(cart, isLensExpansion);
			queryClient.setQueryData(['cart', { cartId }], normalizedCart);
		},
		{
			onMutate: true ? null : ({ lineItems }) => {
				queryClient.cancelQueries(['cart']);
				const cartSnapshot = queryClient.getQueryData(['cart', { cartId }]);

				queryClient.setQueryData<NormalizedCart>(['cart', { cartId }], previousCart => {
					const linesToAdd: NormalizedCartLine[] = lineItems.map(({ customAttributes, variant }) => {
						return {
							id: null,
							variant,
							properties: customAttributes
								? Object.assign({}, ...customAttributes.map(({ key, value }) => ({ [key]: value })))
								: null,
							title: null,
							discountAllocations: null,
							quantity: 1,
							optimistic: true,
						} as NormalizedCartLine;
					});
					const lines: NormalizedCartLine[] = [...(previousCart?.lines ?? []), ...linesToAdd];
					const bundles = generateCartBundles(lines, true);
					const subtotal = calculateOptimisticSubtotal(previousCart, lines);

					return { ...previousCart, bundles, lines, subtotal };
				});

				return { cartSnapshot };
			},
			onError: (error, props, context) => {
				console.error(`Error adding to cart ${cartId}: ${error}`);
				queryClient.setQueryData(['cart', { cartId }], context.cartSnapshot);
				queryClient.invalidateQueries(['cart']);
				showToast(TOAST.LAST_ACTION_ERROR);
			},
			onSettled: () => {
				queryClient.invalidateQueries(['cart']);
			},
		}
	);
};

export { useCartAdd };
