import { Item as DropdownMenuItem, DropdownMenu, DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu';
import { useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { AccountIcon, Button, Close, DropdownMenuContent, Logout } from '@components';
import { useLogout } from '@services/shopify/operations/logout';
import { Title } from '@components/typography';
import { useIsMobile } from '@utils/hooks';
import { EXTRA_SMALL_WIDTH } from '@constants';
import styles from './AccountDropdown.module.scss';

const AccountDropdown = ({ customer }) => {
	const logout = useLogout();
	const isMobile = useIsMobile({ maxWidth: EXTRA_SMALL_WIDTH });
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	const { locale } = useRouter();

	const showMyRewards = locale === 'en-US' || locale === 'en-CA';

	const toggleDropdown = open => {
		// Workaround to prevent the scroll bar from disappearing when the dropdown is open
		// Since the dropdown menu uses react-remove-scroll under the hood, based on:
		// https://github.com/radix-ui/primitives/discussions/1100
		if (open) {
			document.body.style.setProperty('overflow', 'auto', 'important');
			document.body.style.setProperty('margin-right', '0', 'important');
		} else {
			document.body.style.removeProperty('overflow');
			document.body.style.removeProperty('margin-right');
		}
		setDropdownIsOpen(!dropdownIsOpen);
	};

	return (
		<DropdownMenu open={dropdownIsOpen} onOpenChange={toggleDropdown}>
			<DropdownMenuTrigger data-nav-icon='account' data-test={customer ? 'account' : 'login'}>
				<div onMouseEnter={toggleDropdown} className={styles['nav-icon']}>
					<AccountIcon loggedIn={!!customer} label='account' />
				</div>
			</DropdownMenuTrigger>

			<DropdownMenuContent title='Account menu' align={isMobile ? 'center' : 'end'}>
				<div className={styles.container} onMouseLeave={toggleDropdown}>
					<header>
						<Title>{`Hi ${customer.firstName}, nice to see you!`}</Title>
						<Close label='x' onClick={toggleDropdown} />
					</header>
					<ul>
						<li>
							<DropdownMenuItem className={styles.dropdownItem}>
								<Link href={'/account/favorites'}>
									<Button
										label='My Favorites'
										size='large'
										color='white'
										padding='1rem 2.4rem'
										withChevron
										chevronDirection='right'
										removeEffects
										fullWidth
										border='none'
										spaceBetween
									/>
								</Link>
							</DropdownMenuItem>
						</li>
						<li>
							<DropdownMenuItem className={styles.dropdownItem}>
								<Link href={'/account/collection'}>
									<Button
										label='My Collections'
										size='large'
										color='white'
										padding='1rem 2.4rem'
										withChevron
										chevronDirection='right'
										removeEffects
										fullWidth
										border='none'
										spaceBetween
									/>
								</Link>
							</DropdownMenuItem>
						</li>
						<li>
							<DropdownMenuItem className={styles.dropdownItem}>
								<Link href={'/account/orders'}>
									<Button
										label='My Orders'
										size='large'
										color='white'
										padding='1rem 2.4rem'
										withChevron
										chevronDirection='right'
										removeEffects
										fullWidth
										border='none'
										spaceBetween
									/>
								</Link>
							</DropdownMenuItem>
						</li>
						{showMyRewards && (
							<li>
								<DropdownMenuItem className={styles.dropdownItem}>
									<Link href={'/account/rewards'}>
										<Button
											label='My Rewards'
											size='large'
											color='white'
											padding='1rem 2.4rem'
											withChevron
											chevronDirection='right'
											removeEffects
											fullWidth
											border='none'
											spaceBetween
										/>
									</Link>
								</DropdownMenuItem>
							</li>
						)}
						<li>
							<DropdownMenuItem className={styles.dropdownItem}>
								<Button
									size='large'
									color='white'
									padding='1rem 2.4rem'
									spaceBetween
									removeEffects
									fullWidth
									border='none'
									onClick={logout}
								>
									Log Out
									<Logout />
								</Button>
							</DropdownMenuItem>
						</li>
					</ul>
				</div>
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

export default AccountDropdown;
