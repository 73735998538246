import { ReactNode, useMemo } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { BADGE_TYPES, LOCALE_CODES } from '@constants';
import { Button, Container, Img, Lozenge } from '@components';
import { MenuGroup } from '@ts/contentful';
import styles from './ImagesMenu.module.scss';

const ImagesMenu = ({ menuGroup }: { menuGroup: MenuGroup }) => {
	const { locale } = useRouter();
	const notShowKidsMenu = locale === LOCALE_CODES.UK;
	const imageMenus = useMemo((): ReactNode[] => {
		return menuGroup.subMenuGroup.map(({ handle, image, link, text, collectionTag = undefined }, index) => {
			if (notShowKidsMenu && text === 'Shop Kids') {
				return null;
			}
			return (
				<ul key={handle ?? `subnav-container-${index}`}>
					<li role='none' data-handle={handle} key={handle ?? `subnav-item-${index}`}>
						<Link href={link} role='menuitem' tabIndex={0}>
							<Img noSrcset src={image.url} alt={text} width={500} height={250} />
							<Button size='medium' color='white'>
								{text}
							</Button>
							{collectionTag === 'New' && <Lozenge shape='square' size='regular' backgroundColor={BADGE_TYPES.NEW.backgroundColor} extraClasses={styles['images-menu-lozenge']}>
								{BADGE_TYPES.NEW.displayText}
							</Lozenge>}
						</Link>
					</li>
				</ul>
			);
		})
	}, [notShowKidsMenu])

	return (
		<Container className={styles.container}>
			{imageMenus}
		</Container>
	);
};

export default ImagesMenu;
