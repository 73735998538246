import { createContext, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import {
	BUILD_FLOW_STEP_DIRECTION,
	BUILD_FLOW_STEPS,
	BUILD_FLOW_STEPS_LENS_EXPANSION,
	CUSTOMER_JOURNEYS,
	LENS_COLORS,
	LENSES_PACKAGES,
} from '@constants';
import { COLLECTION_LISTS } from '@constants/contentful';
import { NormalizedVariant } from '@ts/product';
import { BFContextProps, BFProviderProps } from '@ts/index';
import { useHasMounted, useIsLensExpansionTest } from '@utils/hooks';
import { BASE_FRAME_LENS_OPTIONS, RX_TYPE } from '@utils/constants/base-skus';
import useBaseFrameVariant, {
	createBaseFrameVariant,
	useBaseFrameVariantPrices,
} from '@services/shopify/hooks/useBaseFrameVariant';
import { getOptionsForRxType, handelize, LOCALE_DICT } from '..';

const BFContext = createContext<BFContextProps>(null);

export function BFProvider({ children, props }: BFProviderProps) {
	const [journey] = useState(props.journey);
	const { isLensExpansion: isLensExp, isLensExpansionExpB } = useIsLensExpansionTest();
	const isLensExpansion = isLensExp && journey !== CUSTOMER_JOURNEYS.SUNGLASSES;
	const bfEnum = isLensExpansion ? BUILD_FLOW_STEPS_LENS_EXPANSION : BUILD_FLOW_STEPS;
	const [collectionListSlug, setCollectionListSlug] = useState(COLLECTION_LISTS.BUILD_FLOW);
	const [defaultLens, setDefaultLens] = useState(props.defaultLens);
	const [frame] = useState(props.frame);
	const [frameColor, setFrameColor] = useState(props.frameColor);
	const [rxType, setRxType] = useState(props.rxType);
	const [product, setProduct] = useState(props.product);
	const [readerRx, setReaderRx] = useState(props.readerRx ?? null);
	const lensOptions = getOptionsForRxType(props.lensOptions, rxType);
	const [lensPackage, setLensPackage] = useState(props.lensPackage);
	const [selectedLenses, setSelectedLenses] = useState(props.selectedLenses ?? []);
	const selectedLensesInVariantOptionForm = createBaseFrameVariant(selectedLenses, !(isLensExpansionExpB && selectedLenses.includes(BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS)) && !!lensPackage && lensPackage === LENSES_PACKAGES.BASIC);
	const lensColor = selectedLenses.find(lens => lens.includes('Sun'))?.split(" - ")?.[1] as LENS_COLORS;

	const [step, setStep] = useState<BUILD_FLOW_STEPS | BUILD_FLOW_STEPS_LENS_EXPANSION>(props.step);
	const [stepChange, setStepChange] = useState<BUILD_FLOW_STEP_DIRECTION>(null);
	const [tops, setTops] = useState(props.tops ?? {});
	const [variantImages, setVariantImages] = useState(props.variantImages);
	const { locale } = useRouter();
	const countryCode = LOCALE_DICT[locale].countryCode;

	const { data: variantPrices, refetch } = useBaseFrameVariantPrices(props.product.handle + `-${handelize(frameColor)}`, countryCode, isLensExpansion)
	const BaseFrameVariant = useBaseFrameVariant({
		handle: props.product.handle + `-${handelize(frameColor)}`,
		Color: frameColor,
		'Rx Type': rxType,
		'Lens': selectedLensesInVariantOptionForm,
		country: countryCode,
		isLensExpansionTest: isLensExpansion,
	});

	const { data: selectedVariant } = BaseFrameVariant;
	const basePrice = isLensExpansion && !!lensPackage && lensPackage !== LENSES_PACKAGES.BASIC ? selectedVariant?.variantBySelectedOptions?.metafields?.cr39AbTest : selectedVariant?.variantBySelectedOptions?.price?.amount;

	const subtotal = parseInt(basePrice) + Object.values(tops).reduce((a, c) => a + c.price.amount, 0);
	const isMounted = useHasMounted();

	const isTailoredExperienceTest = (typeof window !== 'undefined' && window.isTailoredExperienceTest) ?? false;

	useEffect(() => {
		if ('collectionListSlug' in props && isTailoredExperienceTest && journey === CUSTOMER_JOURNEYS.EYEGLASSES) {
			setCollectionListSlug(props.collectionListSlug);
		}
	}, [isTailoredExperienceTest, journey, props]);

	useEffect(() => {
		refetch();
	}, [isLensExpansion]);

	function handleCustomLens(lensOption: BASE_FRAME_LENS_OPTIONS) {
		let updatedSelections = [];
		if (selectedLenses.includes(lensOption)) {
			updatedSelections = selectedLenses.filter((option) => option !== lensOption);
			setSelectedLenses(updatedSelections);
		} else {
			const isLensPremiumPlus = lensOption === BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS;
			const isLensCR39 = lensOption === BASE_FRAME_LENS_OPTIONS.CR39;
			if (!isLensExpansion || ((!isLensPremiumPlus || isLensExpansionExpB) && !isLensCR39)) {
				updatedSelections = [...selectedLenses];
			}
			updatedSelections = [...updatedSelections, lensOption];

			if (isLensExpansionExpB && updatedSelections.includes(BASE_FRAME_LENS_OPTIONS.CR39) && updatedSelections.includes(BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS)) {
				updatedSelections = updatedSelections.filter(option => option !== BASE_FRAME_LENS_OPTIONS.CR39);
			}

			setSelectedLenses(updatedSelections);
		}
	}

	function handleTop(top: NormalizedVariant) {
		if (tops.hasOwnProperty(top.handle)) {
			const newTops = { ...tops };
			delete newTops[top.handle];
			setTops(newTops);
		} else {
			setTops({ ...tops, [top.handle]: top });
		}
	}

	// Step change handler
	useEffect(() => {
		if (stepChange === BUILD_FLOW_STEP_DIRECTION.NEXT) {
			setStepChange(null);
			const withInSteps = step + 1 < Object.keys(bfEnum).length / 2;
			const skipCustomize = journey !== CUSTOMER_JOURNEYS.EYEGLASSES && rxType !== RX_TYPE.READERS;

			if (!withInSteps) return;
			if (!isLensExpansion && step === BUILD_FLOW_STEPS.CUSTOMIZE && rxType === RX_TYPE.READERS && readerRx === null) return;

			if (!isLensExpansion && step + 1 === BUILD_FLOW_STEPS.CUSTOMIZE && skipCustomize) {
				setStep(bfEnum.TOP_FRAMES);
				return;
			}

			setStep(step + 1);
		}

		if (stepChange === BUILD_FLOW_STEP_DIRECTION.PREV) {
			setStepChange(null);
			const withInSteps = step - 1 >= 0;
			const skipCustomize = journey !== CUSTOMER_JOURNEYS.EYEGLASSES && rxType !== RX_TYPE.READERS;

			if (!withInSteps) return;

			if (!isLensExpansion && step - 1 === BUILD_FLOW_STEPS.CUSTOMIZE && skipCustomize) {
				setStep(bfEnum.LENS);
				return;
			}

			setStep(step - 1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stepChange]);

	useEffect(() => {
		if (rxType === RX_TYPE.READERS || rxType === RX_TYPE.NON_RX) {
			setSelectedLenses(current => current.filter(lens => lens !== BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS))
		}
	}, [rxType]);

	useEffect(() => {
		if (props.product) {
			setProduct(props.product);
		}
		if (step === bfEnum.COLOR) {
			setFrameColor(props.frameColor);
			setTops(props.tops);
			setSelectedLenses(props.selectedLenses ?? []);
			setRxType(props.rxType);
			setReaderRx(props.readerRx);
			setLensPackage(props.lensPackage);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props])

	if (!isMounted) return;

	return (
		<BFContext.Provider
			value={{
				collectionListSlug,
				setCollectionListSlug,
				defaultLens,
				setDefaultLens,
				frame,
				frameColor,
				setFrameColor,
				journey,
				lensColor,
				rxType,
				setRxType,
				product,
				setProduct,
				readerRx,
				setReaderRx,
				lensOptions,
				selectedLenses,
				setSelectedLenses,
				step,
				setStep,
				setStepChange,
				subtotal,
				tops,
				handleCustomLens,
				handleTop,
				variantImages,
				setVariantImages,
				BaseFrameVariant,
				variantPrices: variantPrices || props.variantPrices,
				lensPackage,
				setLensPackage,
			}}
		>
			{children}
		</BFContext.Provider>
	);
}

export function useBFContext() {
	return useContext(BFContext);
}
