import { productVariantFragment } from "@services/shopify/fragments/variant";

const cartQuery = /* GraphQL */ `
	query cart(
		$id: ID!
		$country: CountryCode = US
	) @inContext(country: $country) {
		cart(id: $id) {
			checkoutUrl
			id
			lines(first: 100) {
				edges {
					node {
						id
						quantity
						merchandise {
							...productVariantFragment
						}
						attributes {
							key
							value
						}
						cost {
							subtotalAmount {
								amount
								currencyCode
							}
							totalAmount {
								amount
								currencyCode
							}
						}
					}
				}
			}
			totalQuantity
			buyerIdentity {
				countryCode
			}
		}
	}
	${productVariantFragment}
`;

export { cartQuery };
