import { useRouter } from 'next/router';
import cn from 'classnames';
import { Title, Label } from '@components/typography';
import { Container, Link } from '@components';
import ReferWidget from '../ReferWidget';
import AllTopsWidget from '../AllTopsWidget';
import LensesWidget from '../LensesWidget';
import styles from './AllTopsMenu.module.scss';

const AllTopsMenu = ({ menuGroup }) => {
	const { locale } = useRouter();
	const isLensesMenu = menuGroup.handle === 'lenses' // visibility handled in MenuGroup.tsx
	const showReferWidget = locale === 'en-US' || locale === 'en-CA';
	return (
		<Container className={cn(styles.container, { [styles['lenses-container']]: isLensesMenu })}>
			{menuGroup.showWidget && !showReferWidget && !isLensesMenu && <AllTopsWidget />}
			{menuGroup.subMenuGroup.map(({ handle, subMenuGroup, text }) => {
				return (
					<ul key={handle ?? `subnav-container-${handle}`}>
						<li data-handle={handle} key={`submenu-group-${handle}`}>
							<Title>{text}</Title>

							<ul style={{ marginLeft: '-0.3rem' }}>
								{subMenuGroup.map((subSubNavItem, index) => (
									<li
										data-handle={subSubNavItem.handle}
										key={`sub-sub-menu-group-${handle + index}`}
										className={styles['list-item']}
									>
										{subSubNavItem.collectionTag ? (
											<>
												<div data-type={subSubNavItem.collectionTag} className={styles['item-tag']} />
												<Label
													data-type={subSubNavItem.collectionTag}
													className={styles['item-tag-label']}
												>
													{subSubNavItem.collectionTag}
												</Label>
											</>
										) : null}
										<Link href={subSubNavItem.link ?? '#'} small>
											{subSubNavItem.text}
										</Link>
									</li>
								))}
							</ul>
						</li>
					</ul>
				);
			})}
			{menuGroup.showWidget && showReferWidget && !isLensesMenu && <ReferWidget />}
			{ isLensesMenu && <LensesWidget />}
		</Container>
	);
};

export default AllTopsMenu;
