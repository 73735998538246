import React from 'react';
import { useRouter } from 'next/router';
import { slideUp } from '@utils/motions';
import { Caption, Paragraph, Title } from '@components/typography';
import { LOCALE_DICT } from '@constants';
import { useSiteSettings } from '@services/contentful';
import { Close, getThreshold } from '@components';
import { useLocaleContext } from '@context';
import { LOCALE_CODES, formatCurrency } from '@utils/index';
import Modal from '../Modal';
import Flex from '../Flex';
import Button from '../Button';
import styles from './InternationalWelcomeModal.module.scss';

const InternationalWelcomeModal = () => {
	const { locale } = useRouter();
	const { data: siteSettings } = useSiteSettings();
	const { openModal, setOpenModal, handleModalClose } = useLocaleContext();
	const threshold = getThreshold(locale, siteSettings) ?? 0;
	const { copy, currencyCode } = LOCALE_DICT[locale];
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
	const formattedThreshold = formatCurrency({ amount: threshold, currencyCode, locale }, showCurr);

	return (
		<Modal open={openModal} onOpenChange={setOpenModal}>
			<Modal.Content className={styles.container} customAnimation={slideUp} customClose removePadding>
				<Modal.Close className={styles['close-button']}>
					<Close label='Close' onClick={() => setOpenModal(false)} />
				</Modal.Close>
				<Flex align='start' className={styles.copy}>
					<Title style={{ fontSize: '2.4rem', lineHeight: '2.4rem' }}>Welcome to Pair Eyewear</Title>
					<div>
						<Caption>You’re shopping in {copy}</Caption>
						<Paragraph>
							<ul>
								<li>Shop in local currency</li>
								<li>All duties and taxes are included, no hidden fees</li>
								<li>
									Free standard shipping on orders over {formattedThreshold}
								</li>
							</ul>
						</Paragraph>
					</div>
					<Flex className={styles['footer-buttons']} fullWidth>
						<Modal.Close>
							<Button
								label='Continue Shopping'
								size='medium'
								fullWidth
								extraClasses={styles['continue-button']}
								onClick={() => handleModalClose(locale)}
							/>
						</Modal.Close>
					</Flex>
				</Flex>
			</Modal.Content>
		</Modal>
	);
};

export default InternationalWelcomeModal;
