import React, { createContext, FC, ForwardedRef, forwardRef, ReactNode, useContext, useEffect, useState } from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { m, AnimatePresence } from 'framer-motion';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useAllTopsDrawerContext, useCartContext } from '@context';
import { fade, slideFromRight } from '@utils/motions';
import styles from './Drawer.module.scss';

interface DrawerContentProps {
	children: ReactNode;
	open?: boolean;
	setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
	ref?: ForwardedRef<HTMLDivElement>;
	fullHeightOnMobile?: boolean;
	blurOverlay?: boolean;
	grayBackground?: boolean;
}

const DrawerContent: FC<DrawerContentProps> = forwardRef(
	({ children, fullHeightOnMobile, blurOverlay = true, grayBackground = false, ...props }, forwardedRef) => {
		const open = useContext(DrawerContext);
		const overlayClasses = cn({
			[styles['overlay']]: blurOverlay,
			[styles['overlayWithoutBlur']]: !blurOverlay,
		});
		const contentClasses = cn({
			[styles['content']]: !fullHeightOnMobile,
			[styles['fullHeight']]: fullHeightOnMobile,
			[styles['gray']]: grayBackground,
		});

		return (
			<AnimatePresence>
				{open ?
					<DialogPrimitive.Portal forceMount>
						<DialogPrimitive.Overlay asChild forceMount className={overlayClasses}>
							<m.div {...fade}></m.div>
						</DialogPrimitive.Overlay>
						<DialogPrimitive.Content
							{...props}
							asChild
							forceMount
							className={contentClasses}
							ref={forwardedRef}
						>
							<m.div {...(slideFromRight)}>{children}</m.div>
						</DialogPrimitive.Content>
					</DialogPrimitive.Portal>
					: null}
			</AnimatePresence>
		)
	}
);

const DrawerContext = createContext(false);

const Drawer = ({ children }) => {
	const { setIsAllTopsDrawerOpen } = useAllTopsDrawerContext();
	const { lastActiveItem, setLastActiveItem } = useCartContext()
	const [open, setOpen] = useState(false);
	const router = useRouter();

	useEffect(() => {
		const handleRouteChange = () => {
			setOpen(false);
			setIsAllTopsDrawerOpen(false)
		};

		router.events.on('routeChangeStart', handleRouteChange);

		return () => {
			router.events.off('routeChangeStart', handleRouteChange);
		};
	}, [router])

	const handleChange = (o: boolean) => {
		setIsAllTopsDrawerOpen(o)
		setOpen(o)

		if (o === false && lastActiveItem?.current) {
			setTimeout(() => {
				lastActiveItem.current.focus()
				setLastActiveItem(null)
			}, 1000)
		}
	}

	return (
		<DialogPrimitive.Root onOpenChange={handleChange} open={open}>
			<DrawerContext.Provider value={open}>{children}</DrawerContext.Provider>
		</DialogPrimitive.Root >
	);
};

DrawerContent.displayName = 'DrawerContent';
Drawer.Content = DrawerContent;
Drawer.Trigger = DialogPrimitive.Trigger;
Drawer.Close = DialogPrimitive.Close;

export default Drawer;
