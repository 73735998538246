import React from 'react';
import { useRouter } from 'next/router';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { EDIT_LENS_SELECTION, LOCALE_CODES, PREMIUM_ULTRA_THIN_COPY } from '@constants';
import { Flex, Tooltip, Lozenge } from '@components';
import { Paragraph, Title, TypographyButton } from '@components/typography';
import { formatCurrency } from '@utils/shopify';
import { BASE_FRAME_LENS_OPTIONS, RX_TYPE } from '@utils/constants/base-skus';

import variables from '@styles/export.module.scss';
import styles from './EditabilityLensBody.module.scss';

const EditabilityLensBody = ({ name, price, copy, tag = null, tooltip = false, preSelectedTooltip = false, usePlusSign = false, hidePrice = false, selectedLenses = [] }) => {
	const { locale } = useRouter();
	const isVarifocalCopyTestActive = useFeatureIsOn('is-progressives-copy-test'); 

	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
	const isLightResponsiveSelected = selectedLenses?.includes(BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE);
	const isBlueLightOption = name.includes('Blue-Light')
	const showIncludedTag = isLightResponsiveSelected && isBlueLightOption;

	const testingTitle = isVarifocalCopyTestActive && locale === 'en-GB' && name === RX_TYPE.PROGRESSIVE ? 'Varifocal' : name;


	return (
		<Flex column fullWidth>
			<Flex style={{ width: '100%' }} justify='between' align='center'>
				<Flex column gap={2}>
					{
						tag && <Lozenge 
							text={showIncludedTag ? 'Included' : tag} 
							shape='square' 
							backgroundColor={showIncludedTag ? variables.greenLight : variables.gray2} 
							color={showIncludedTag ? variables.green2 : variables.gray4} />
					}
					<Title>{testingTitle}</Title>
				</Flex>
				<Flex style={{ height: 'fit-content', flexShrink: '0' }} align='center' gap={3}>
					{tooltip && name === 'Premium Plus' && <Tooltip message={PREMIUM_ULTRA_THIN_COPY} />}
					{preSelectedTooltip && <Tooltip message={EDIT_LENS_SELECTION} />}
					{!hidePrice && (
						<TypographyButton small className={styles.price}>
							{usePlusSign ? '+' : ''}{!!price && formatCurrency(price, showCurr)}
						</TypographyButton>
					)}
				</Flex>
			</Flex>
			<Paragraph style={{ marginTop: '0.6rem' }}>{copy}</Paragraph>
		</Flex>
	);
};

export default EditabilityLensBody;
