import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useHasMounted } from '@utils/hooks';
import styles from './KlarnaPlacement.module.scss';

type KlarnaPlacementProps = {
	subtotal: number;
};

const KlarnaPlacement = ({ subtotal }: KlarnaPlacementProps) => {
	const { locale } = useRouter();
	const hasMounted = useHasMounted();
	const isKlarnaTestActive = useFeatureIsOn('klarna-prominence');
	const useVariant = isKlarnaTestActive && !!subtotal;

	useEffect(() => {
		if(hasMounted && window?.Klarna && locale !== 'en-CA') {
			try {
				window?.Klarna?.OnsiteMessaging?.refresh();
			} catch (error) {
				console.error(`Klarna script could not be refreshed! ERR: ${error}`)
			}
		}
	}, [hasMounted, locale, subtotal]);

	return locale !== 'en-CA' ? (
		<div id='klarna-placement' className={styles.container}>
			{useVariant && <style>
				{`
					#klarnaLeft::part(osm-badge) {
						width: 4rem;
					}
					#klarnaLeft::part(osm-badge-container) {
						width: 4rem;
						margin-right: 4px;
					}
					#klarnaLeft::part(osm-container) {
						flex-direction: row;
					}
					#klarnaLeft::part(osm-message) {
						margin: 0px!important;
					}
    		`}
			</style>}
			<klarna-placement
				data-key={useVariant ? 'credit-promotion-badge' : 'top-strip-promotion-auto-size'}
				data-locale={locale}
				data-purchase-amount={subtotal * 100 ?? 0}
				id='klarnaLeft'
			/>
		</div>
	) : null;
};

export default KlarnaPlacement;
